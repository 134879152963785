.product-selection {
  .selector-size {
    height: $selector-size-inpage-height-mobile;

    .selector__menu {
      padding: 1.6rem 0 1.4rem;

      &__list {
        row-gap: $selector-size-inpage-rowgap-mobile;
        grid-template-columns: repeat(8, 1fr);
      }
    }
  }
}
