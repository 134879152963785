// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  .selector__menu {
    &__list {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      &__element{
        flex: none;
      }
    }
  }
}
