.sticky-tray {
  position: fixed;
  bottom: 0;
  padding: 1.2rem 2.4rem;
  width: 100%;
  background-color: $white;
  visibility: hidden;
  @include z('stickybar-m');
  transition-property: opacity;
  opacity: 0;
  max-width: 100%;
  transition: all 0.4s 0s cubic-bezier(0.25, 1, 0.5, 1);

  .swatch-color-container {
    .label-color-value {
      white-space: nowrap;
    }
  }

  &.show {
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    visibility: visible;
    display: block;

    @include animate(0.4);
  }

  &__img-name-price {
    display: flex;
    padding: 0;
    justify-content: space-between;
    align-items: center;

    .selector-label-wrapper {
      display: flex;
      align-items: center;

      .selector__label {
        display: flex;
        width: 100%;
        align-items: center;
        padding-right: 2.4rem;

        &-value--color {
          white-space: nowrap;
        }
      }
    }
  }

  &__name-price {
    display: flex;
    flex: 0 0 auto;
  }

  &__price {
    .price {
      margin-bottom: 0;
      font-size: 1.4rem;
    }
  }

  &__name {
    font-size: 1.4rem;
    line-height: 2rem;

    &::after {
      content: "|";
      padding: 0 1.2rem;
    }
  }

  &__modal.modal {
    width: 100%;
    min-width: initial;
    max-width: initial;
    height: auto;
    right: 0;
    left: 0;
    bottom: 4.8rem;
    top: initial;
    padding-top: 5.5rem;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-delay: 0s;
    opacity: 1;

    &.ReactModal__Content {
      transform: translateY(3.2rem);

      .size-default-wrapper {
        display: none;
      }

      &--after-open {
        transform: translateY(0);
        top: unset;
        bottom: 8rem;
        left: unset;
        right: 2.4rem;
        padding: 0;
        border-radius: 0.4rem;
        width: 37.2rem;
      }

      &--before-close {
        transform: translateY(3.2rem);
      }
    }

    .selector{
      &-color-expanded {
        .selector__label {
          padding-top: 1.5rem;
        }
      }

      &-size-expanded {
      .selector__header {
        padding-top: 1.5rem;
      }
    }
    }
  }

  &__overlay {
    &.overlay-modal {
      transition-duration: 0.3s;
      background: $black_secondary;
      z-index: 10000 !important;
    }
  }

  .sticky-tray__wrapper .product-image:before {
    padding: 0;
    height: 6.8rem;
  }
}