// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  .selector__menu {
    &__item {
      .swatch {
        margin-right: 0;
        @include touchtarget-area($target-size-mobile);

        &::after {
          width: $swatch-size-mobile;
        }
      }
    }

    &.mobile-color-modal {
      padding: 0;

      .color__group__wrapper {
        padding: 0;
        width: 100%;

        &:first-child {
          padding: 0;
        }

        .wrapper-loop {
          display: flex;
          flex-direction: column;
          margin: 0 1.6rem;

          .selector__menu__list__element {
            padding: 0 1.6rem;
            border-bottom: 0.1rem solid $gray3;

            .selector__menu__item {
              justify-content: space-between;
              flex-direction: row;
              padding: 1.8rem 0;
              width: 100%;
              height: fit-content;
              align-items: center;

              .is-disabled,
              &.notavailable {
                &::after {
                  top: 1.8rem;
                }
              }

              &__label {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

body {
  &#product-show {
    .selector-color-expanded {
      .selector__menu {
        .color__group__wrapper {
          width: 100%;
        }
      }
    }
  }
}