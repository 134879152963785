.prices__info-wrapper .tooltip {
  &-container {
    transform: translateX(-80%);
    max-width: min(35vw, 24rem);
    &:after{
      left: 80%;
    }
  }
}

.grid-container {
  &.grid-2 {
    .product-tile {
      &:not(.product-tile-horizontal, .alternateTile, .product-tile-horizontal-small) {
        &:nth-of-type(5n + 2),
        &:nth-of-type(5n + 4) {
          .tooltip {
            &-container {
              transform: translateX(-89%);
              &::after {
                left: unset;
                right: 0;
              }
            }
          }          
        }

      }      
    }
  }

}

