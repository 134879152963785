.grid-container {
  column-gap: 1.6rem;
  padding-bottom: 6.4rem;

  .grid-footer {
    grid-column: span 12;
    width: fit-content;
    margin: 4rem auto 0;
  }

  .pages-number {
    width: 100%;
    justify-content: space-between;
  }

  .page-numbers-block {
    margin-left: 2.4rem;
    margin-right: 2.4rem;

    :last-child {
      margin-right: 0;
    }

    :first-child {
      margin-left: 0;
    }
  }

  &.grid-1 {
    row-gap: 2.4rem;

    .product-image:hover {
      .tile-swiper-icon {
        opacity: 1;
      }
    }

    .product-tile {
      grid-column: span 12;
    }
  }
}

.grid-container.grid-4 {
  gap: 1.2rem;
}

.swiper-subcategories-button__wrapper {
  display: none;
}



.plp-product-label {
  &.not-available {
    margin: 0.2rem 0;
  }
}

#maincontent {
  .plpSeo {
    &__container {
      width: 100%;
    }

    &__accordion {
      &-header {
        &[aria-expanded='false'] {
          padding: 0 0 2.4rem;
        }
      }

      &-panel {
        p {
          &:last-of-type {
            padding-bottom: 2.4rem;
          }
        }
      }
    }
  }
}


.listing-container {
  padding-top: 1.6rem;

  .plp-top .plp-top-container .wrapper-page-title {
    padding-bottom: 2.4rem;
  }

  .page-title {
    margin-bottom: 0;
  }

  .plp-top-container {
    margin-bottom: 3.2rem;
  }

  .product-tile .swiper-button-container {
    display: none;
  }
}

.plp-top-container {
  padding: 0;

  .wrapper-page-title {
    padding: 0 3.2rem 0 1.6rem;
  }
  .wrapper-subcontainer {
    padding: 0 1.6rem;
  }

  .subcategories-container:not(.swiper-container) {

    .item-wrapper {
      padding: 0 1.6rem;
    }

  }


  .plp-top-search-field {
    &::before {
      content: '';
      @include fixedSprite('search');
      position: absolute;
      bottom: 1.4rem;
    }

    input {
      background: transparent;
      border: 0;
      border-bottom: 0.1rem solid $gray6;
      padding: 0 0 0.8rem 3.6rem;
      width: 100%;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    .icon--close {
      display: none;
    }
  }
}

.listing-container.listing-brand {
  padding-top: calc(var(--nav-wrapper) + 1.6rem);
}

#maincontent .wrapper-noresult-white {
  .product-counter {
    margin-bottom: 6.4rem;
  }

}

.suggested-categories-container {
  padding: 0 0 6rem;

  .suggested-categories {
    flex-direction: column;

    .suggested-category-dropdown {
      .accordion__header {
        justify-content: center;
        padding: 0;
      }

      .suggested-category {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
        margin: 1.2rem 0;
        border-bottom: 0;
        width: 100%;

        > :first-child {
          padding-top: 1.2rem;
        }

        > :last-child:not(:first-child) {
          padding-bottom: 1.2rem;
        }
      }
    }
  }
}