// COLOR SELECTOR USED IN: PDP, Reserve in boutique
body {
  &#product-show {
    .selector-color-expanded {
      .selector__menu {
        .color__group__wrapper {
          width: auto;
        }
      }
    }
  }
}

.selector-color-expanded {
  position: relative;
  color: $black;

  .label-color-value {
    color: $blue;
  }

  .selector__label {
    display: flex;
    align-items: center;
    padding: 0;

    &-text {
      text-transform: unset;
      font-size: 1.4rem;
      color: $grey7;
      @extend %primary-xs-u;
      margin-right: 0.4rem;
    }

    &-value--color {
      color: $blue;
      @extend %primary-xs;
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    .selector__button__info {
      margin-left: ($space-unit * 2);
      opacity: 0.5;
      text-transform: initial;
    }
  }

  .selector__menu {
    display: flex;
    align-items: center;
    margin: 1.6rem 0 3.2rem;
    flex-wrap: wrap;

    .color__group__wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 0 1.6rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .wrapper-loop {
        gap: 0.8rem 1.2rem;

        .selector__menu__list__element {
          display: flex;
          justify-content: flex-start;
          margin: 0;
          padding: 0;
        }
      }
    }

    &__item {

      &.is-disabled,
      &.notavailable {
        opacity: 0.6;
        pointer-events: none;

        &::after {
          content: '';
          @include fixedSprite('swatch-outofstock');
          position: absolute;
        }
      }
    }

    .color-section-price {
      &[data-salecolor=true] {
        color: $blue_promo;
      }
    }

    .label {
      @extend .visually-hidden;
    }

    &__list {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;


      &__element:first-child {
        margin-left: 0.1rem;
      }

    }

    .color-expanded-divider {
      border-left: 0.1rem solid $grey3;
      position: absolute;
      height: 100%;
      right: 0;
    }

    &__item {
      height: 2rem;
      width: 2rem;
      justify-content: center;
      display: flex;
      align-items: center;
      position: relative;

      &:hover:not(.selected) {
        .swatch {
          &::after {
            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      &__label {
        display: none;
      }
    }

    &.mobile-color-modal {
      padding-bottom: 0.8rem;

      .color__group__wrapper {
        .selector__menu__item {
          flex-direction: column;
          align-items: flex-start;

          .color-section-price {
            margin-left: 0;
            padding-bottom: 0;
          }
        }
      }

      .swatch-wrapper {
        display: flex;

        .swatch {
          margin-right: 0.8rem;
        }

        .color-swatch-name {
          display: none;
        }
      }

      .color__group__wrapper {
        &::before {
          content: "";
          border-left: 0.1rem solid $grey3;
          position: absolute;
          left: 0;
          height: 100%
        }

        &:first-child::before {
          content: unset;
        }

        &:first-child {
          padding-left: 1.6rem
        }
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;

    .selector__menu__item {
      color: inherit;

      &.selected {
        .swatch {
          &::after {
            background-color: $white;
          }
        }
      }

      &:focus {
        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }
    }
  }
}
