body {
  .size-guide-tag-wrapper {
    display: none;
  }

  &#product-show {
    .size-guide-tag-wrapper {
      display: flex;
    }
  }
}

.sizevariation-modal.modal,
.info-modal {
  padding: 0;
  border-radius: 0.4rem 0.4rem 0 0;

  .size-label-model-info-mobile {
    border-bottom: 0.1rem solid $grey3;
    padding: 1.6rem 0;
    margin: 0 1.6rem;
    color: $grey6;
  }

  .modal__body {
    background: $white;
  }

  .modal__body .product-selection__wrapper .selector-size-expanded .selector__menu {
    padding: 0;

    .size-selector-label-wrapper {
      border: 0;
      padding: 0;

      .size-default-wrapper {
        height: 6.4rem;
        padding: 0;
        align-items: center;
        border-bottom: 0.1rem solid $grey3;

        &::after {
          content: none;
        }
      }

      ul {
        max-height: 100%;
        height: 100%;
        padding: 0 1.6rem;

        li {
          display: flex;
          align-items: center;
          margin: 0;
          border-radius: 0.4rem;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .product-selection__wrapper {
    padding: 0;

    .counter {
      padding: 1rem;
      border: 0.1rem solid $grey3;
      border-radius: 0.4rem;
      height: 4.7rem;
      display: flex;
      align-items: center;

      .btn__container {
        display: flex;
        align-items: center;
      }

      .counter__output {
        padding: 0 1.8rem;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
      }
    }

    .quantity-label-selector-wrapper {
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem 1.6rem;
      border-top: 0.1rem solid $grey3;
    }
  }

  .size-guide-tag-wrapper {
    position: absolute;
    top: calc(0rem - 4rem - 2.2rem);
    background: $white;
    border-radius: 4rem;
    padding: 1.4rem 1.6rem;
    left: 2.4rem;
    display: flex;
    align-items: center;
    text-decoration: unset;

    .size-guide-tag {
      font-size: 1.3rem;
      line-height: 1.2rem;
    }
  }

  .modal__header {
    padding: 2.2rem 1.6rem;
    border-bottom: 0.1rem solid $grey3;

    .dialog1_label {
      padding: 0;
    }

    h2 {
      color: $blue;
    }
  }

  .selector-size-expanded .selector__menu .size-selector-label-wrapper .size-default-wrapper {
    display: none;
  }

  .product-selection__ctas {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.1rem solid $grey3;

    button {
      width: calc(100% - 4.8rem);
    }
  }
}

body {
  .sizevariation-modal {
    &.modal {
      overflow: visible;
      bottom: 0;
      top: unset;
      max-width: 100%;
      
      &.whishlist-selectsize {
        border: none;
      }
    }
  }

  &#product-show {
    .sizevariation-modal {
      &.whishlist-selectsize {
        height: auto;

        .modal__body {
          height: 100%;

          .product-selection__wrapper {
            overflow: auto;

            ul {
              padding: 0;
            }
          }

          .product-selection__ctas {
            margin-top: 2.4rem;
            position: relative;
            padding: 0;
            border-top: 0;
          }
        }
      }
    }
  }

  &#account-wishlist{
    .modal {
      &.sizevariation-modal {
        &.quickview-selectsize {
          width: 100%;
          height: auto;
          top: unset;

          .modal__body {
            width: 100%;

            .productDetails{
              &__img{
                &-wrapper{
                  width: 8.8rem;
                }
              }
              
              &__body{
                &-wrapper{
                  width: 100%;
                  height: auto;                  
                }

                &__details{
                  height: auto;
                }
              }
            }
          }
        }
      }
    }  
  } 
}


.info-modal,
.address-modal {
  .modal__body {
    padding: 2.4rem;

    div:not(.invalid-feedback) {
      font-size: 2rem;
      line-height: 3.5rem;


      &.default-payment-instrument-container,
      &.new-payment-card-description,
      &.gift-header-description {
        font-size: 1.4rem;
        line-height: 2rem;

      }
    }
  }
}

.modal.info-modal,
.modal.address-modal {
  height: fit-content;
  margin-top: auto;
}
