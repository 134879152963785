.product-breadcrumb {
  display: block;
}

body{
  &#product-show{
    .breadcrumb{
      padding-bottom: 0;
      display: inline-block;
      width: 91.5vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      li{
        display: inline-flex;
      }
    }
  }
}
