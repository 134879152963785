.product-hero,
.product-hero--placeholder {
  position: relative;
  overflow: hidden;
}

.product-hero {
  width: 100%;
  text-align: left;
  display: block;
  height: 100%;

  &-gallery{

    .swiper{
      &-slide {
        @include animate();
        pointer-events: none;
    
        &.swiper-slide-active {
          opacity: 1 !important;
          pointer-events: auto;
        }
      }
    }
  }
}

.cursor {
  display: none;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  @include z(cursor);
  @include animate;

  &--hidden {
    opacity: 0;
  }
}

.th-player-external-element .th-controls,
.th-player .th-controls {
    display: none !important;
}


.contactus-modal {
  .contact-us {
    .line {
      margin-top: 3.2rem;
      display: flex;
      align-items: flex-end;
    }

    &__desc {
      margin-top: 1.5rem;
      margin-bottom: 3.34rem;
    }
  }
}
