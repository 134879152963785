body#search-show {
  background-color: $gray2;
}

.tile-body {
  margin-bottom: 1.2rem;

  .row {
    justify-content: space-between;
  }
}

body#product-show {
  .prices .price .price {
    margin-bottom: 0;
    gap: 1.2rem;
  }
}

.promo-text {
  color: $blue_promo;
  line-height: 1rem;
  display: flex;
  align-items: center;

  &[data-promo-type='employee'],
  &[data-promo-type='promo-dipendenti'] {
    color: $green;
    background: unset;
    padding: 0;
  }
}

.price,
.prices .price .price {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  line-height: 1.6rem;


  .sales[data-saletype='sale'],
  .sales[data-saletype='promotion'],
  .sales[data-saletype='sales'],
  .sales[data-saletype='employee'],
  .sales[data-promo-type='promo-dipendenti'] {
    background-color: $lilac;
    padding: 0.4rem;
    .value {
      &--promo {
        color: $blue_promo;
      }
    }
  }

}

#search-show,
#product-show {
  main {
    padding-top: 0;
  }
}

#search-show .product-carousel__carousel-container {
  background: $gray1;
}

[data-react='catalog-app'] {
  position: relative;
}

.listing-container {
  padding-top: 4rem;

  &.loading {
    pointer-events: none;

    .product-tile__image {
      transition: initial;
    }
  }

  .wrapper-noresult-white {
    .product-counter {
      background-color: $white;
    }
  }

  .wrapper-refine-button {
    margin-top: $space-unit;
  }

  .refine {
    @include animate(0.4);
    transition-property: transform, opacity;
  }

  .shelf-container {
    &:first-child {
      .refine {
        position: fixed;
      }
    }

    &:not(:first-child) {
      .refine {
        position: relative;
        top: initial;
        z-index: 1;
      }
    }
  }

  .filter-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .page-title {
    text-align: left;
    margin-bottom: 2rem;
    position: relative;
  }
}

.plp-page-title {
  &-inline {
    display: inline-table;    
  }

  &-flex {
    display: flex;
    
    img.brand-logo {
      height: auto;
      width: var(--brandLogoWidth);
    }    
  }

  

}

.absolute-number {
  vertical-align: top;
  white-space: nowrap;
}

.product-grid__item {
  border: $space-line solid red;
  padding: 1rem;
  margin: 1rem;
}

#maincontent{
  .plpSeo{
    &__wrapper{
      padding: 0 2.4rem 8rem;
      background-color: $gray1;

      .col {
        padding: 0;
      }
    }

    &__container{
      color: $grey8;
    }

    &__accordion{
      &-header{
        &[aria-expanded='true'] {
          padding: 0 0 1.6rem;
        }

        &[aria-expanded='false'] {
          padding: 0 0 3.2rem;
        } 

        &::after {
          align-self: center;
        }
      }

      &-panel{
        padding: 0;
        p {
          color: $grey7;
          
          &:first-of-type{
            font-size: 1.3rem;
            line-height: 1.6rem;
            text-transform: uppercase;
          }

          &:last-of-type{
            padding-bottom: 3.2rem;
            font-size: 1.6rem;
            line-height: 2rem; /* 125% */
          }
        }
      }
    }

    &__body{
      strong {
        font-weight: bolder;
      }

      a {
        color: $black;
        text-decoration: none;

        &:hover{
          text-decoration: underline;
        }
      }

      h2 {
        font-size: large;
        font-weight: bolder;
        margin-top: 4rem;
        margin-bottom: 1rem;      
      }

      h3 {
        font-size: small;
        font-weight: bolder;
        margin-top: 2rem;
      }

    }
  }  
}


.grid-container {
  @include reset-list;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  width: 100%;
  gap: 2.8rem 1.2rem;
  padding-bottom: 8rem;

  &--loading {
    visibility: hidden;
  }

  li {
    min-width: 0;
  }

  .product-tile {
    grid-column: span 3;

    &__card {
      overflow: visible;
    }

    &--oos {
      .product-image {
        opacity: 0.6;
      }
    }

    .cta-availability-notice {
      display: none;
    }
  }

  &.grid-2 {
    .product-tile {
      grid-column: span 6;

      &:not(.product-tile-horizontal, .alternateTile, .product-tile-horizontal-small):nth-of-type(5n) {
        grid-column: span 12;

        .product-tile {
          .tile-body {
            .tile-last-components {
              flex-direction: row;
            }
          }
        }
      }

      &.product-tile-horizontal {
        grid-column: span 12;

        &-small {
          grid-column: span 12;
        }
      }

      &.product-tile-horizontal,
      &.product-tile-horizontal-small {
        .product-tile {
          .tile-body {
            .tile-last-components {
              flex-direction: row;
            }
          }
        }
      }

      &.alternateTile {
        .plp-mobile-color-size-selection {
          display: none;
        }
      }

      &.product-tile-horizontal,
      &.product-tile-horizontal-small {
        .product-image-horizontal {
          &:before {
            padding-top: 68.13%;
          }

          &-fallback {
            background-color: $grey1;

            &:before {
              padding-top: 68.13%;
            }

            img {
              width: calc(50% - 0.8rem);
              height: 100%;

              &:last-of-type {
                left: unset;
              }
            }
          }
        }
      }
    }

    .product-tile .tile-body .tile-last-components {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }
  }


  &.grid-4 {
    .product-tile {
      grid-column: span 3;

      &.product-tile-horizontal {
        grid-column: span 12;
        padding: 5.2rem 17.5rem;

        &-small {
          grid-column: span 6;
        }
      }

      &.product-tile-horizontal,
      &.product-tile-horizontal-small {
        .product-image-horizontal {
          &:before {
            padding-top: 68.13%;
          }

          &-fallback {
            background-color: $grey1;

            &:before {
              padding-top: 68.13%;
            }

            img {
              width: calc(50% - 0.6rem);
              height: 100%;

              &:last-of-type {
                left: unset;
              }
            }
          }
        }
      }
    }
  }

  &.grid-6 {
    gap: 0.8rem;

    .product-tile {
      grid-column: span 2;
    }

    .image-viewtype-recession {
      opacity: 1;
    }

    .tile-body,
    .variation-color-plp,
    .cta-show-colors {
      display: none;
    }

    .product-tile:hover {
      .tile-swiper-icon {
        opacity: 0;
      }
    }

    .editorial-grid-position {

      &--1,
      &--2,
      &--3,
      &--4 {
        display: none;
      }
    }
  }

  &.grid-1 {
    .product-tile {
      grid-column: span 12;
    }
  }

  .editorial-grid-position {
    &--1 {
      grid-row-start: 2;
      grid-column: 3 / 5;
    }

    &--2 {
      grid-row-start: 4;
      grid-column: 5 / 7;
    }

    &--3 {
      grid-row-start: 7;
      grid-column: 1 / 3;
    }

    &--4 {
      display: none;
    }
  }

  .grid-footer {
    grid-column: span 12;
    margin-top: 5.2rem;
  }

  .plppromo {
    .plppromo__title {
      top: $space-unit * 8;
      left: $space-unit * 8;
    }

    &.is-double {
      picture {
        height: 100%;
      }

      img {
        position: relative;
        top: initial;
        transform: initial;
      }
    }
  }

  &.lookbook {
    li {
      &:nth-child(4n + 2) {
        .shop-by-look-module {
          left: -100%;
        }
      }

      &:nth-child(4n + 3) {
        .shop-by-look-module {
          left: -200%;
        }
      }

      &:nth-child(4n + 4) {
        .shop-by-look-module {
          left: -300%;
        }
      }
    }
  }

  .plppromo {

    &--2x2,
    &--2x1 {
      &:focus {
        outline: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 0.2rem $outline;
          @include z;
        }
      }
    }

    &--2x2 {
      grid-column: span 2;
      grid-row: span 2;
    }

    &--2x1 {
      grid-column: span 2;
    }
  }

  .pages-number {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    width: fit-content;
    height: 2.4rem;
    padding: 0;

    .btn.inactive {
      opacity: 0.2;
    }

    .disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.plp-loading,
.plp-error {
  display: flex;
  align-items: center;
  padding-top: ($space-unit * 16);
  padding-bottom: ($space-unit * 4);
  @extend %primary-s-u;
}

.plp-error {
  &::before {
    content: '';
    @include fixedSprite('alert');
    width: $space-unit * 4;
    height: $space-unit * 4;
    margin-right: $space-unit * 2;
  }
}

.plppromo {
  position: relative;

  .plppromo__link {
    display: block;
    position: relative;
    height: 100%;
  }

  .plppromo__title {
    position: absolute;
    top: $space-unit * 4;
    left: $space-unit * 4;
    text-transform: uppercase;
    @include z;

    &.white-title {
      color: $white;
    }
  }

  picture {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.shelf-container {
  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $space-unit * 3;
    padding-bottom: $space-unit * 6;
    text-decoration: initial;

    i {
      margin-top: -0.2rem;
    }
  }
}

.editmode {
  .plppromoarea {
    background-color: yellow;
  }

  .experience-region {

    &.experience-promo1,
    &.experience-promo2,
    &.experience-promo3,
    &.experience-promo4,
    &.experience-promo5,
    &.experience-promo6 {
      width: 100%;
      height: 100%;
    }
  }

  .experience-component_plppromo {
    width: 100%;
    height: 100%;
  }
}

.listing-container .product-counter {
  background: $gray1;
}

.page-numbers-block {
  margin-left: 1.6rem;
  margin-right: 1.6rem;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
}

a.btn-n-page,
span.btn-n-page {
  color: $gray7;
  text-decoration: none;
  padding: 0.2rem;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 0 0.6rem;

  &.active {
    background-color: $blue;
    color: $white;
  }

  &.last {
    opacity: 0.8;
  }
}

.swiper-subcategories-button-prev,
.swiper-subcategories-button-next {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  &:disabled {
    opacity: 0.3;
  }
}

.btn-subcategory {
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-radius: 0.4rem;
  padding: 0;
  background-color: $white;
  border: 0.1rem solid $gray3;
  color: $blue;
  height: 3.2rem;

  &:not(:first-child){
    margin-left: 0.8rem;
  }

  &-link {
    width: 100%;
    height: 100%;
  }

  &:not(.swiper-slide) {
    &.selected {
      border: none;
      background-color: $blue;

      span {
        color: $white;
      }

      &-white {
        background-color: $blue;

        span {
          color: $white;
        }
      }
    }
  }

  .subcategory-image {
    margin: auto;
  }
}

.plp-top {
  .wrapper-subcontainer {
    height: 100%;

    .subcategories-container.swiper-container {
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 0;

      .swiper-subcategories-button__wrapper {
        z-index: 2;
        height: 100%;
        background-color: $gray2;
        display: flex;
        align-items: center;

        &--blue {
          background-color: $blue;
        }
      }

      .swiper-wrapper {
        align-items: center;
        width: 100%;

        .swiper-slide {

          .btn-subcategory,
          &.btn-subcategory {
            padding: 0;
            margin-right: 0;
            height: 17.2rem;
            width: 17.2rem;
            max-width: 17.2rem;
            text-align: center;
            text-decoration: none;
            color: $blue;
            border: 0;
            justify-content: space-around;

            &-link {
              padding: 1.2rem;
              height: 100%;
            }

            &.selected-white {
              border: 0.1rem solid $white;

              .btn-subcategory-name {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .subcategories-container:not(.swiper-container) {
    display: flex;
    overflow-x: auto;
    flex-direction: column;
    padding: 0;
    overflow-y: hidden;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    a {
      text-decoration: none;
      text-align: center;
    }

    .item-wrapper {
      flex: none;
      margin: 0;
      height: 3.2rem;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      will-change: transform;
      user-select: none;
      -webkit-user-drag: none;
      cursor: pointer;
      margin-top: auto;

      &.active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }

      a {
        //pointer-events: none;
        user-select: none;
        -webkit-user-drag: none;
        padding: 0.7rem 1.5rem;
      }

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
  }
}

.grid-product.refinement-grid {
  padding-top: 4rem;
  background: $gray1;
  overflow-x: hidden;
  border-bottom: 0.1rem solid $gray1;
  overflow: visible;
}

.plp-product-labels,
.plp-product-promo-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: absolute;
  top: unset;
  bottom: 0;
  padding: 0 0.8rem;
  z-index: 1;
}

.promo-text {
  .plp-product-label {
    color: $blue_promo;

    >*{
      color: $blue_promo;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.3rem;
      line-height: 2rem;
      font-weight: 500;
    }
  }

  &:has(.tooltip) {
    .plp-product-label {
      margin: 0;
    }
  }
}

.plp-product-label {
  display: inline-block;
  line-height: 1.6rem;
  font-weight: 500;

  &.not-available {
    color: $grey6;
    display: block;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &-promo {
    color: $blue_promo;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 500;

    >*{
      color: $blue_promo;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.3rem;
      line-height: 2rem;
      font-weight: 500;
    }

    &[data-promo-type='employee'],
    &[data-promo-type='promo-dipendenti'] {
      color: $green;
      background: unset;
    }
  }
}

.sale-product-label {
  background-color: $red;
  color: $white;
}

.grid-1 {
  column-gap: 1.2rem;
  row-gap: 1.2rem;
}

.button-prova {
  width: 1rem;
  height: 1rem;
  background: red;
}

.price-card {
  .price-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .price-range-slider {
    width: 100%;
    position: relative;
    padding: 1.6rem 0;
    height: 3.6rem;

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      background: transparent;
      position: absolute;
      left: -0.2rem;

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.2rem;
        background: $blue;
        border-radius: 1rem;
        border: none;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        background: $white;
        cursor: pointer;
        border: 0.2rem solid $blue;
        margin-top: -0.8rem;
        position: relative;
        z-index: 1;
      }
    }
  }
}

.change-grid {
  &.change-grid-1x {
    &::before {
      content: '';
      @include fixedSprite('view_1x');
      opacity: 0.3;
    }

    &.selected {
      &::before {
        content: '';
        @include fixedSprite('view_1x');
        opacity: 1;
      }
    }
  }

  &.change-grid-2x {
    &::before {
      content: '';
      @include fixedSprite('view_2x');
      opacity: 0.3;
    }

    &.selected {
      &::before {
        content: '';
        @include fixedSprite('view_2x');
        opacity: 1;
      }
    }
  }

  &.change-grid-4x {
    &::before {
      content: '';
      @include fixedSprite('view-4x');
      opacity: 0.3;
    }

    &.selected {
      &::before {
        content: '';
        @include fixedSprite('view-4x');
        opacity: 1;
      }
    }
  }

  &.change-grid-6x {
    &::before {
      content: '';
      @include fixedSprite('view-6x');
      opacity: 0.3;
    }

    &.selected {
      &::before {
        content: '';
        @include fixedSprite('view-6x');
        opacity: 1;
      }
    }
  }
}

.suggested-categories-container {
  min-height: 16rem;
  padding: 8rem 0;

  .suggested-categories {
    display: flex;
    gap: 1.2rem;

    .suggested-category {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.2rem;
      flex: 1 0 0;

      .suggested-category-title {
        margin-bottom: 1.2rem;
      }
    }
  }
}

.listing-container.listing-brand {
  padding-top: calc(var(--nav-wrapper) + 5rem);
}

.listing-container.listing-brand.sub-cat-img {
  padding-top: calc(var(--nav-wrapper) + 0.8rem);
}