.product-selection {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  height: 100%;

  &--loading {
    opacity: 0;
  }

  &__line {
    margin: 0 0 ($space-unit * 2) 0;
    padding: 0 0 ($space-unit * 2) 0;
    @include border('bottom');

    [class^='icon'] {
      margin-right: ($space-unit * 2);
      width: 1.4rem;
      height: 2rem;
    }

    &-name{
      text-align: left;
      display: flex;
      align-items: center;

      &-link {
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        text-decoration: none;
      }

      &_exclusive {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__description {
    @extend %primary-xs;
    margin: 0 0 ($space-unit * 7);
  }

  &__preorder {
    @extend %primary-xs-u;
    margin-bottom: $space-unit * 4;
    text-align: left;
  }

  &__name-price {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: ($space-unit * 2);

    &__name {
      font-size: 1.3rem;
      text-align: left;
      padding-right: ($space-unit * 8);
      line-height: 1.2;
      padding-bottom: 2.4rem;
    }

    &__price {
      font-size: 1.3rem;
      flex-shrink: 0;
    }
  }

  &__ctas {
    display: flex;
    
    &--personalization {
      background: transparent;
      border: 0.1rem solid $grey3;
    }

    .personalization__bottom__message {
      padding: 0.8rem;
      border-radius: 0.4rem;
      background-color: $grey2;        
      align-self: flex-start;
      width: 100%;
    }

    .button {
      @include text-shorten;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__actions-shipping {
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;

    &__contact-us {
      height: 1.6rem;
    }

    .button+.button {
      margin-left: ($space-unit * 6);
    }

    &__shipping {
      @extend %primary-xs;
      text-transform: capitalize;
    }
  }

  &__modelinfo {
    position: relative;
    margin: $space-unit * 9 0 $space-unit * 1;
    padding-left: $space-unit * 6;
    @include sprite('info');
    background-position: left;
    background-repeat: no-repeat;
    background-size: $space-unit * 4 $space-unit * 4;
    color: $black_secondary;
    min-height: $space-unit * 4;
    display: flex;
    align-items: center;
  }

  &__size {
    display: flex;
    justify-content: space-between;

    .cta--animated {
      color: $grey8;
      font-size: 1.4rem;
      text-decoration: none;
      border-bottom: 0.1rem solid $grey8;


      &::before {
        content: none;
      }
    }

    .label-size {
      color: $grey7;
    }
  }

  .size-container-section {
    display: flex;
    padding: 1.2rem 0 1.6rem;
    gap: 1.2rem;

    .selector-size-expanded {
      flex: 1 0 0;
      min-width: 66%;
      max-width: 100%;
    }

    .counter {
      padding: 1.2rem 1.6rem;
      border: 0.1rem solid $grey3;
      background: $white;
      border-radius: 0.4rem;
      height: 4.7rem;
      display: flex;
      align-items: center;

      .btn__container {
        display: flex;
        justify-content: space-between;

        .counter__output {
          padding: 0 1.2rem;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
        }
      }
    }
  }
}

.quickview-selectsize {
  .product-selection {
    .size-container-section {
      padding-bottom: 0.8rem;
    }
  }
}

.customize-button {
  margin-bottom: 1.2rem;

  .gradient-icon {
    width: 1.2rem;
    height: 1.2rem;
    display: block;
    position: relative;
    border-radius: 2rem;
    margin: 0.4rem;

    &::after {
      content: '';
      background: conic-gradient(rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%);
      z-index: 0;
      position: absolute;
      border-radius: 1.6rem;
      top: -0.2rem;
      bottom: -0.2rem;
      left: -0.2rem;
      right: -0.2rem;
    }

    &::before {
      content: '';
      background: $white;
      z-index: 1;
      position: absolute;
      border-radius: 1.6rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.9rem;
      height: 0.9rem;
    }
  }
}

.collection-modal {
  &.modal {
    min-width: 0;
    width: 54.9rem;
    max-width: 100%;
    padding: 3.2rem 3.6rem;
  }

  &__title-wrapper {
    font-size: 1.3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;

    [class^='icon'] {
      width: 1.4rem;
      height: 2rem;
    }
  }

  &__title {
    margin-left: ($space-unit * 2);
  }

  &__btn-container {
    text-align: center;
    margin-top: 2.7rem;
  }

  .button {
    display: inline-flex;
    width: auto;
    min-width: 22.6rem;
  }
}