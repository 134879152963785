.product-wall {
  &__wrapper {
    padding: 5.6rem 1.6rem;
  }

  &__title {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
  
  &__product {
    grid-column: span 6;
    &-container {
      row-gap: 1.6rem;
    }
    &:nth-of-type(2n) {
      .tooltip-container {
        transform: translateX(-94%);
        &::after {
          left: 94%;
        }
      }  
    }
  }
} 