// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  @include border('top');
  color: $black;
  height: $selector-size-inpage-height-desktop;
  @include animate(0.5, height);

  .selector__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    &-text {
      @extend %primary-xs-u;
    }

    &[aria-expanded='true'] {
      opacity: 0;
      visibility: hidden;

      + .selector__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled {
      opacity: 1;
      color: inherit;
      cursor: inherit;
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      right: -#{$space-unit};
      @include fixedSprite('chevron-right');
      @include center('vertical');
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    &__label {
      margin-left: ($space-unit * 4);
      text-transform: capitalize;

      &--outofstock {
        color: $black_secondary;
      }
    }

    &__info {
      margin-left: ($space-unit * 2);
      color: $black_secondary;
      text-transform: initial;
    }
  }

  .selector__menu {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: initial;
    left: 0;
    padding: 1.1rem 0 0.8rem; // pixel perfect ehm
    opacity: 0;
    visibility: hidden;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    .label {
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: grid;
      bottom: initial;
      min-width: 96%;
      grid-template-columns: repeat($selector-size-inpage-cols-desktop, 1fr);
      row-gap: $selector-size-inpage-rowgap-desktop;
    }

    &__item {
      & > span {
        @extend %primary-xs-u;
      }

      &.is-disabled {
        color: $black_secondary;
      }

      &:hover {
        // ONE-23809
        text-decoration: underline;
      }

      @include touchtarget-area();

      &:focus {
        @include touchtarget-focus();
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;

    .selector__button {
      .selector__button__label--outofstock,
      .selector__button__info {
        color: $white_secondary;
      }
      color: $white;
      &::after {
        filter: invert(1);
      }
    }

    .selector__menu {
      background-color: $black;
    }

    .selector__menu__item {
      color: $white;

      &.is-disabled {
        color: $white_secondary;
      }

      &[aria-pressed='true'] {
        .swatch {
          outline-color: $white_inactive;
        }
      }

      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }
    }
  }
}
