.product-thumbnails-zoom {
  flex-direction: row;
  width: 100%;
  overflow: auto;
  bottom: 2.4rem;
  gap: 0.8rem;
  left: 0;
  top: unset;
  height: fit-content;
  padding: 0 1.8rem;
  align-items: flex-end;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    height: 0;
  }

  img, video {
    height: 9rem;
    margin-bottom: 0;
  }
}

.product-detail {
  .product-added-to-bag {
    position: fixed;
    bottom: 0;
    left: 0.8rem;
    z-index: 900;
    width: calc(100% - 1.6rem);
    display: flex;
    align-items: center;
    padding: 0.8rem;

    img {
      width: 4.8rem;
      height: auto;
      margin-right: 2.4rem;
    }

    span {
      width: 40%;
    }

    a {
      margin-left: auto;
      margin-right: 0.8rem;
    }
  }

  &__name{
    &--wrapper{
      align-items: center;
      position: relative;    
    }
  
    &--container {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 3rem;
    }
  
    .icon--wishlist {
      position: absolute;
      top: 0;
      right: 0;
    }    
  }

  &__info-assets {
    .content-asset:first-child {
      margin-top: 2.4rem;
    }

    .content-asset:last-child {
      margin-bottom: 2.4rem;
    }
  }

  .product-gallery-button {
    display: none;
  }
}

.product-description {
  margin-bottom: 6rem;
}

#product-details-bottom {
  display: block;
  padding: 0 1.6rem;
  margin-top: 6.4rem;
  margin-bottom: 0;
}

.productDetailsBottom {
  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__description {
      margin: 0;

      &-text {
        font-size: 1.6rem;
        line-height: 2rem;
      }      
    }

    &__oekoTex {
      &_element {
        &-body {
          &-title {
            &-wrapper {
              flex-direction: column;
              gap: 0.2rem;
              align-items: flex-start;
            }
          }
        }
      }
    }

    &__model-info {
      margin: 1.6rem 0 0;
    }

    .product-label__code {
      margin-bottom: 1.6rem;
    }

    &__techDetails {
      &-body{
        &-content {
          flex: 0 1 auto;
          width: 100%;
          
          &-wrapper{
            flex: auto;            
          }
        }
      }
    }
  }

  &__info {
    width: 100%;
    padding: 0;
    margin-top: 3.2rem;
    
    &__careLabels {
      padding-bottom: 1.6rem;
    }
  }
}

#sticky-bar-bottom {
  display: none;
}