.modal.sizeguide-pdp-modal {
  max-width: unset;
  max-height: 85%;
  top: unset;
  border-radius: 0;

  .wrapper-size-guide-app {
    width: 100%;
    padding: 2.4rem 1.6rem;

    .table-wrapper {
      overflow-x: auto;
    }

    .text-baby {
      .text-baby-description {
        padding-left: 0;
      }
    }
  }
}