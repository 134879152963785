// to hide slides resizing
.swiper{
  &-container,
  &-wrapper,
  &-wrapper-carousel {
    @include animate;
  }

  &-container:not(.swiper-container-initialized):not(.product-image) {
    opacity: 0;
    visibility: hidden;
  }
}

.swiperLoading {
  &__container {
    background-color: $white;
    height: calc((100vw* 4.9261 / 19) + 5.681rem);
    &--suggested {
      background-color: $gray1;
    }
  }     
}


body:not(#search-show) {
  .product-carousel__carousel-container {
    padding-top: var(--paddingRecommendations);

    &+.product-carousel__carousel-container {
      padding-top: 0;
    }

    &.cart {
      padding-top: 4.8rem;
    }
  }

  .swiperLoading {
    &__container {
      background-color: transparent;
    }     
  } 
}

.product-carousel__carousel-container,
#product-details-wear-it-with {
  .product{
    &-tile{

      &-wrapper-element {
        z-index: 0;
      }

      .tile-body {
        >.row {
          align-items: flex-start;

          &.tile-last-components {
            margin: 0;
          }

          .wrapper-title {
            flex: 1 0 0;

            .product-brand {
              color: $grey8;
            }

            h2 {
              max-width: 100%;
            }
          }
        }

        .variation-color-plp {
          padding: 0.2rem 0;

          .variation-color-mobile-counter {
            color: $grey7;
          }
        }
      }
    }
  }
}

.product-carousel__carousel-container {
  position: relative;
  overflow: hidden;

  .product-tile {
    &__card {
      overflow: visible;
    }

    &:last-child {
      .tooltip-container {
        transform: translateX(-93%);
        &:after {
          left: 100%;
          transform: translate(-154%, 1.6rem);
        }
      }
    }
  }

  .swiper-button-container {
    &:hover {
      .swiper-button-disabled {
        opacity: 0.3;
      }      
    }
  }

  .recommendations-carousel {
    overflow: hidden;

    &:not(:has(.swiper-wrapper--loading)) {
      padding: 5.6rem 0;      
    }

    .product-carousel__swiper-container {
      padding: 0 2.4rem;
      &:has(.product-tile-labels) {
        .product-carousel-swiper-button--plp {
          top: calc(50% - 11rem / 2);
          transform: translateY(60%)
        }
      }
      .product-tile-wrapper-element {
        z-index: unset;
        .tile-body {
          margin-bottom: 0;

          .variation-color-plp {
            display: none;
          }        
        }        
      }

    }
    
    &.recently-viewed {
      background-color: $white;
    }

    &:hover {
      .product-carousel-swiper-button:not(.swiper-button-disabled) {
        display: block;
      }
    }
    .product-carousel {
      &-swiper-button {
        width: 3.2rem;
        height: 3.2rem;
        background-color: $white_btn;
        display: none;
        top: calc(50%);
        margin-top: 0;
        transform: translateY(50%);

        &--prev {
          left: 2.4rem;
        }
        &--next {
          right: 2.7rem;
        }

        &--plp {
          top: calc(50% - 9rem / 2);
          transform: translateY(62%);
          background-color: $white;
        }
      }
    }

    &.suggested-products {
      background-color: $grey2;
    }
    &.trending-products {
      background-color: $gray1;
    }

    &.recently-viewed,
    &.suggested-products,
    &.trending-products {
      .plp-mobile-color-size-selection {
        display: none;
      }
    }

    .product-carousel__title {
      margin-top: 0;
      font-size: 3.2rem;
      line-height: 4rem;
      margin: auto;
      text-align: center;
      &-wrapper {
        padding-bottom: 3.2rem;

        &.product-carousel-wrapper {
          .swiper-button-container {
            display: flex;
            gap: 2rem;
            height: 3.2rem;
          }
        }        
      }
      


    }
  }
}

#product-details-wear-it-with {
  padding: 8rem 14rem 9.6rem 14rem;
  margin-top: 0;
  background-color: $grey2;

  .product-tile-wrapper-element {
    z-index: 0;
  }

  .complete-your-look{
    &-carousel {
      overflow: hidden;
      list-style: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2rem;
      row-gap: 4rem;
      grid-template-rows: auto;
      width: 100%;
    }

    &-title {
      color: rgba(0, 21, 38, 0.9);
      font-size: 2.4rem;

      &-labels {
        display: none;
      }
    }

    &-container {
      .swiper-button-container:hover {
        .swiper-button-disabled {
          opacity: 0.3;
        }
      }
    }
  }

  .shop-the-look-btn {
    margin: auto 0 auto auto;
  }

  .swiper-recommendations-button{
    &-prev,
    &-next {
      position: absolute;
      top: 50%;      
    }
    
    &-prev{
      left: 7rem;
    }

    &-next{
      right: 7rem;
    }
  }
}
