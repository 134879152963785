// grid settings
// prettier-ignore
@use "sass:math";

$grid-columns: 12;

// 0 -> 767 MOBILE; 768 -> 1279 TABLET; 1280 -> full DESKTOP // MODIFICARE ANCHE IN \\resolutionHandler.ts\\window.mqObj={...}\\
$grid-breakpoints: (
  sm: 320px,
  md: 768px,
  lg: 1280px,
);

$grid-gutter: (
  sm: 1.2rem,
  md: 1.2rem,
  lg: 2.4rem,
);

$base-remxs: 1.2rem;
$half-remxs: math.div($base-remxs, 2);
$base-remlg: 1.2rem;
$half-remlg: math.div($base-remlg, 2);
$pading-xs: 1.2rem;
$pading-lg: 2.4rem;

$grid-metrics: (
  sm: (gutter: $base-remxs,
    half-gutter: $half-remxs,
    container-padding: $pading-xs,
    column-width: calc((100% / #{$grid-columns}) - $pading-xs),
    color: rgba(241, 126, 126, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-xs),
  ),
  md: (gutter: $base-remxs,
    half-gutter: $half-remxs,
    container-padding: $pading-xs,
    column-width: calc((100% / #{$grid-columns}) - $pading-xs),
    color: rgba(130, 241, 126, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-xs),
  ),
  lg: (gutter: $base-remlg,
    half-gutter: $half-remlg,
    container-padding: $pading-lg,
    column-width: calc((100% / #{$grid-columns}) - $pading-lg),
    color: rgba(218, 126, 241, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-lg),
  )
);

$grid-overlay-base-color: rgba(2, 187, 156, 0.12);
$product-ratio: 138.645%;
$product-ratio-num: math.div(4, 3);