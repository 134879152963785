.scroll-to-section-id.active {
  border-bottom: 0.2rem solid $blue;
  padding: 0 0 0.4rem;
}

#product-details-bottom {
  display: flex;
  margin-top: 12rem;
  padding: 0 14rem 4rem;
  
  .productDetailsBottom__row__enrichments-body-content{
    align-items: center;
    gap: 1.6rem;
    flex: 0 0 auto;
    width: 50%;
    padding: 0 1.6rem 1.6rem 0;

    &:last-child {
      padding-right: 0;
    }
  }
  
  .productDetailsBottom__row__enrichments-body-content-img{
    width: 2.4rem;
    height: 2.4rem;
  }
}

.productDetailsBottom {
  &__row {
    width: 50%;    

    &__description{
      margin: 1.2rem 0 0;

      &-text {
        font-size: 2rem;
        line-height: 2.6rem;
        color: $blue;
      }      
    }

    &__oekoTex {
      &_element {
        &-img {
          max-width: 8.8rem;

          &-divider {
            width: 0.4rem;
            background-color: $grey3;
            height: 100%;
          }
        }


        &-body {
          &-container {
            justify-content: center;
          }
          &-title {
            &-divider {
              background-color: $blue;
              opacity: 0.2;
              width: 0.1rem;
              height: 1.2rem;
            }
          }

          &-btn {
            &-wrapper {
              font-size: 1.3rem;
              line-height: 2.0rem;
            }
          }
        }
      }

    }

    &__model-info{
      margin: 2.4rem 0 0;
      color: $grey7;      
    }

    &__enrichments{

      &-container{
        margin-top: 3.2rem;        
      }

      &-header{
        margin-bottom: 2.4rem;
        gap: 1.2rem;   
      }

      &-title{
        border-bottom: 0.2rem solid $grey3;
        color: $grey7;
        text-transform: uppercase;
        display: block;
        padding-bottom: 0.8rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.3rem;

        &-wrapper {
          flex: 1 0 0;
          cursor: pointer; 

          &.active {
            .productDetailsBottom__row__enrichments-title {
              color: $blue;
              border-bottom: 0.2rem solid $blue;
            }
          }
        }
      }

      &-body{
        padding-bottom: 1.6rem;
  
        &:last-child {
          padding-bottom: 0;
        }

        &-content{

          &-title {
            color: $blue;
            margin-bottom: 0.4rem;
            display: block;
          }
  
          &-description{
            color: $grey8;          
          }
        }
      }
    }
  }

  &__info {
    width: 50%;
    padding: 0 0 0 12.3rem;
    &__careLabels {
      padding-bottom: 2.8rem;

      &__list {
        @include reset-list;
        display: flex;
        gap: 1.6rem;
        padding-bottom: 0.8rem;

        &__element {
          cursor: pointer;

          &.selected {
            border-bottom: 0.1rem solid $blue;
          }

          &:not(.selected) {
            filter: brightness(0) saturate(100%) invert(66%) sepia(1%) saturate(3836%) hue-rotate(165deg) brightness(87%) contrast(96%);
          }
        }
      }
    }
    
    &__composition {
      display: flex;
      gap: 0.4rem;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 2.4rem;
      padding-bottom: 2.4rem;
      line-height: 2.4rem;
    }
    .sustainability-block-item-wrapper {
      .block-item {
        padding-bottom: 1.6rem;
        margin-bottom: 1.6rem;
        border-bottom: 0.1rem solid $grey3;
  
        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}


.product-detail {
  &__name {
    font-size: 1.6rem;
    padding-right: 3rem;

    &--wrapper {
      margin-bottom: 3rem;

      display: flex;
      justify-content: space-between;
      margin-top: 0.8rem;
      align-items: flex-start;
    }
  }

  &__info-assets {
    .content-asset:first-child {
      margin-top: 2.4rem;
    }

    .content-asset:last-child {
      margin-bottom: 2.4rem;
    }

    p {
      margin: 1.6rem 0;
      display: flex;
      align-items: flex-start;

      &::before {
        margin-right: 0.8rem;
      }

      &.shipping {
        &::before {
          content: '';
          @include fixedSprite('pack');
        }
      }

      &.return {
        &::before {
          content: '';
          @include fixedSprite('return');
        }
      }

      &.eco {
        &::before {
          content: '';
          @include fixedSprite('eco');
        }
      }
    }
  }

  &-labels{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.8rem;    
  }

  .product-gallery-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    padding: 2rem;
    @include rounded-box;

    &::before {
      content: '';
      @include fixedSprite('grid');
      margin-right: 1rem;
    }
  }
}


.product-label {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4;

  color: $grey8;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &.respect {
    color: $status_success;
  }
}

.product-info__item {
  padding: 0 1.6rem 2rem 0;

  &:first-of-type {
    padding-top: 0;
  }

  &--label-u {
    text-transform: uppercase;
    font-size: 1.3rem;
  }
}

.product-thumbnails {
  display: none;

  &-zoom{
    display: none;
    width: 6.6rem;
    height: auto;
    position: fixed;
    top: 0;
    left: 2.4rem;
    bottom: 0;
    align-items: center;
    will-change: auto;
    z-index: 10000;
    max-height: 70vh;
    margin: auto;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    gap: 0.8rem;

    &.visible {
      display: flex;
    }

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    img, video {
      margin-bottom: 0.8rem;
      min-height: 8.8rem;
    }    
  }
}


main #sticky-bar-bottom {
  z-index: 10010;
}