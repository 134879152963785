body {
  &#product-show {
    .product-container {
      .product-sidebar.container {
        top: 0;
        margin-top: 10rem;
        width: 50%;
        padding-top: 0;
        padding-left: 12.3rem;
        padding-right: 0;

        .breadcrumb {
          padding-bottom: 2.4rem;
          padding-right: 14rem;

          .breadcrumb-item {
            padding: 0;

            a {
              color: $blue;
            }
          }
        }
      }
    }

    .product-cell-right-wrapper {
      padding-right: 14rem;
      min-height: 41rem;

      #variation-attrs {
        min-height: 16.4rem;

        .product-selection__ctas {
          &--personalization {
            margin-top: 0.4rem;            
          }
        }
      }

      .product-brand {
        color: $grey8;
        font-size: 1.6rem;
      }

      .find-in-store-btn {
        &.store-selected {
          margin: 0;
          border: 0;
          padding: 0;
          border-bottom: 0.1rem solid $grey8;
        }
      }

      .shipping-date-preview {
        font-size: 1.3rem;
        margin-top: 1.2rem;
        padding-bottom: 0.8rem;

        .js-modal-info-shipping {
          display: inline;
          color: $blue;
          font-size: 1.3rem;
          cursor: pointer;
        }
      }
    }

    .product-images.container {
      overflow: auto;
      height: 100vh;
      width: 50%;
      padding: 0;

      .tag-image--personalization {
        &.hovered {
          width: fit-content;  
          .tag-image__label {
            max-width: 20rem;
            padding: 0.4rem 0.4rem 0.4rem 2.1rem;
          }            
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .product-gallery-with-modal {
        overflow-y: auto;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .tag-image {

        &--personalization {
          &:hover {
            width: fit-content;   
            .tag-image__label {
              max-width: 20rem;
              padding: .4rem .4rem .4rem 2.1rem;
            }
          }          
        }

        &__label {
          display: block;
          width: max-content;
          height: 1.8rem;
          max-width: 1.8rem;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transition: all 0.5s ease-in-out;
          background-color: $white;
          line-height: 1rem;
          padding: 0.4rem 0;
        }

        &-wrapper {
          bottom: calc(var(--scrolling-banner) + var(--ticker-height) + 2.4rem);
          z-index: 2;
          left: 2.4rem;
        }
      }
    }

    main {
      margin-top: 0;
    }
  }
}

.product-container {
  &.product-wrapper>div.main-collapse-translate:not(.product-images.container) {
    top: 6.4rem;
  }
}

.pdp-klarna-desktop {
  width: 100%;
}