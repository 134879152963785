.product-zoom {
  background: $white;
  .swiper-slide {
    @include animate();
    pointer-events: none;

    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: auto;
    }
  }

  img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-width: 100%;
    margin: 0 auto;
  }

  &-modal{
    .swiper-button{
      &-prev,
      &-next{
        display: none;
      }
    }
  
    .modal__body,
    .zoom-img-wrapper,
    .product-zoom,
    .gallery-wrapper,
    .gallery-wrapper *:not(img) {
      width: 100%;
      height: 100%;
    }

    &.modal {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      transform: none;
      .modal {

        &__close {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.6rem;
          height: 5.6rem;
          top: 2.4rem;
          right: 2.4rem;
          @include rounded-box;
  
          &::before {
            @include sprite('close');
          }
        }
      }
    }
  
    .gallery-wrapper {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0;
        width: 100%;
        height: 9.8rem;
        @include animate();
      }
      &.scaling {
        &::before {
          opacity: 0.5;
        }
      }
    }
  }
}

.product-hero-two-images{
  .product-image-button{
    width:50%;
  }
}

.product-image-button {
  display: block;
  width: 100%;
  height: auto;
}


.zoom-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
