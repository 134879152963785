.swatch,
.color-swatch-button {
  border-radius: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  border: 0.1rem solid transparent;

  &:hover {
    border: 0.1rem solid $disabled_txt_grey;
  }

  &.selected {
    border: 0.1rem solid $blue;

    .swatch-color-container {
      border-color: $white;
      border: 0.2rem solid $white;
      outline: $blue solid 0.1rem;
    }
  }

  &-outofstock {
    opacity: 0.3;
    pointer-events: none;
    border: 0.1rem solid $gray8;
    border-radius: 50%;

    &:hover {
      border: 0.1rem solid $gray8;
    }
  }
}

.inverted {
  .swatch {
    &--black {
      border: $space-line solid $white;
    }

    &--white {
      border: none;
    }
  }
}

.color-swatch-button {
  height: 1.2rem;
  width: 1.2rem;

  &.plp {
    width: auto;
    height: auto;
    border: 0;
    gap: 0.8rem;
    background-color: unset;
  }

  .swatch-color-container {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.1rem solid #8D959C;
    overflow: hidden;
    border-radius: 1rem;

    .label-color-value {
      white-space: nowrap;
    }
  }
}

.swatch {
  height: 2rem;
  width: 2rem;

  &--black {
    background-color: black;
  }

  &--blue {
    background-color: rgb(34, 39, 71);
  }

  &--green {
    background-color: rgb(85, 78, 64);
  }

  &--red {
    background-color: rgb(192, 38, 58);
  }

  &--orange {
    background-color: rgb(218, 74, 47);
  }

  &--pink {
    background-color: rgb(206, 169, 162);
  }

  &--purple {
    background-color: rgb(76, 56, 100);
  }

  &--white {
    background-color: rgb(240, 239, 233);
    border: $space-line solid black;
  }

  &--yellow {
    background-color: rgb(255, 183, 0);
  }

  &--grey,
  &--gray {
    background-color: rgb(123, 124, 126);
  }

  &--beige {
    background-color: rgb(202, 192, 174);
  }

  &--miscellaneous {
    background-color: grey;
  }

  &--brown {
    background-color: rgb(81, 58, 56);
  }

  &--Navy,
  &--navy {
    background-color: navy;
  }

  &--silver {
    background-color: silver;
  }

  &--fuxia {
    background-color: fuchsia;
  }

  &--null,
  &--multicolor,
  &--multicolour,
  &--multicoloured {
    background: linear-gradient(180deg,
        rgba(44, 187, 58, 1) 0%,
        rgba(44, 187, 58, 1) 25%,
        rgba(75, 71, 255, 1) 25%,
        rgba(75, 71, 255, 1) 50%,
        rgba(243, 61, 148, 1) 50%,
        rgba(243, 61, 148, 1) 75%,
        rgba(255, 199, 0, 1) 75%);
  }
}