// SIZE SELECTOR USED IN: cart

.selector-size-inline {
  position: relative;
  color: $black;
  display: inline-flex;
  @include animate(0.5, height);
  height: 1.6rem;

  .selector__button {
    position: relative;
    padding-right: $space-unit * 6;
    @extend %primary-xs;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    &::after {
      content: '';
      right: 0;
      @include fixedSprite('chevron-right');
      position: absolute;
      top: -0.1rem;
    }

    &:disabled {
      color: $black;
      &::after {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      opacity: 0;
      visibility: hidden;

      + .selector__menu {
        opacity: 1;
        visibility: visible;
      }
    }

    &__label--outofstock {
      color: $black_secondary;
    }

    &__info {
      margin-left: 1.2rem;
      color: $black_secondary;
    }
  }

  .selector__menu {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    align-items: flex-start;
    @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

    .label {
      @extend %primary-xs;
      margin: 0 2.4rem 0 0;
    }
    
    &__item {
      @extend %primary-xs;
      margin: 0 2.4rem 0 0;
      position: relative;

      &.is-disabled {
        color: $black_secondary;
        cursor: default;
      }

      @include touchtarget-area();

      &:not(.is-disabled) {
        &:hover {
          // ONE-23809
          text-decoration: underline;
        }

        &:focus {
          @include touchtarget-focus();
        }
      }
    }
  }
}
