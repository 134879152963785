body {
  &#product-show {
    background: $gray1;

    .product-container {
      .find-in-store-cta-wrapper {
        .find-in-store-btn {
          border-radius: unset;
          margin-top: 0;
          justify-content: center;
          border: 0;
          padding: 0;

          span {
            border-bottom: 0.1rem solid $grey8;
            color: $grey8;
          }
        }
      }

      .shipping-date-preview {
        &__more {
          border-bottom: 0.1rem solid $blue;
        }
      }

      .tag-image {
        background: $white;
        font-size: 1rem;
        line-height: 1rem;
        color: $blue;
        transition: bottom 0.3s ease;
        height: 1.8rem;
        align-content: center;
        width: fit-content;
        padding: 0.4rem;

        &-wrapper {
          position: absolute;        
          height: fit-content;
          gap: 0.2rem;
        }

        &--personalization {
          padding: 0.4rem 0.3rem;
          cursor: pointer;
          position: relative;
        }
      }
    }

    footer {
      margin-bottom: 6.4rem;
    }
  }
}

.product-container {
  position: relative;
  z-index: 1;
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  //non mettere overflow hidden, rompe il position sticky
  // &:first-child {
  //   padding-top: $header-height-mobile;
  // }

  &.product-wrapper {
    margin: 0;
    top: 0;

    >* {
      padding-top: 2rem;
      transition: all .6s cubic-bezier(0.1, 0.3, 0.4, 1);
    }
  }

  .product-col {
    flex: 1 0 100%;
    max-width: 100%;
  }

  &.pdp-section,
  &.d-lg-block {
    margin: 0;
  }

  .find-in-store-cta-wrapper {
    .selected-store-detail-pdp {
      align-items: center;
      border: 0.1rem solid $grey3;
      padding: 1.2rem 1.6rem;
      border-radius: 0.4rem;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .icon--store {
        flex: 0 0 auto;
        width: auto;
      }

      .pdp-store-selected-grid {
        width: auto;
        flex: 0 0 auto;
        flex-direction: column;
        display: flex;
      }

      .selected-store-detail-pdp-wrapper {
        align-items: center;
      }

      .pdp-store-availability-section {
        align-items: center;
        flex: 0 0 auto;

        .pdp-store-availability-wrapper {
          padding-left: 1.6rem;
          display: flex;
          flex-direction: column;

          .pdp-store-name-availability {
            font-size: 1.3rem;
            color: $blue;
            line-height: 2rem;
          }

          .pdp-store-availability-within {
            font-size: 1.3rem;
            color: $grey7;
            line-height: 2rem;
            margin-top: 0.4rem;
          }

          .pdp-store-available-green {
            font-size: 1.3rem;
            color: $green;
            margin-top: 0.4rem;

            &:before {
              content: "";
              background: $green;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              display: inline-block;
              outline: 0.3rem solid $grey3;
              margin-right: 1.6rem;
            }
          }

          .pdp-store-unavailable-red {
            font-size: 1.3rem;
            color: $red;
            margin-top: 0.4rem;

            &::before {
              content: "";
              background: $red;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              display: inline-block;
              outline: 0.3rem solid $grey3;
              margin-right: 1.6rem;
            }
          }

          .pdp-store-available-yellow {
            font-size: 1.3rem;
            color: $dark-yellow;
            margin-top: 0.4rem;

            &:before {
              content: "";
              background: $dark-yellow;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              display: inline-block;
              outline: 0.3rem solid $grey3;
              margin-right: 1.6rem;
            }
          }
        }
      }

      .find-in-store-btn-container {
        width: auto;
        flex: 1 0 0;
        text-align: right;

        button {
          font-size: 1.3rem;
          padding: 0;
        }

        &.store-selected {
          flex: 0 0 auto;

          button {
            margin: 0;
            border: 0;
            padding: 0;
            border-bottom: 0.1rem solid $grey8;
          }
        }
      }
    }

    .find-in-store-btn {
      color: $grey8;
      font-size: 1.4rem;
      border-radius: 0.4rem;
      border: 0.1rem solid $grey3;
      margin-top: 3.2rem;
      padding: 1.6rem;
      width: 100%;

      &::before {
        content: unset;
      }
    }
  }

  .prices {
    &__info {
      &-wrapper {
        gap: 0.8rem 1.2rem;
        .pdp-product-label-promo {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
  }

}

.info-shipping-modal {
  transition: unset;
  border-radius: 0.8rem;

  .modal-header {
    .shipping-and-return {
      font-size: 1.6rem;
    }
  }

  .modal-content {
    padding: 2.4rem;
    height: 100%;

    .modal-content-block {
      border-bottom: 0.1rem solid $grey3;
      padding-bottom: 1.6rem;
      margin-bottom: 1.6rem;
      align-items: flex-start;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .modal-content-description {
        h4 {
          font-size: 1.4rem;
          color: $blue;
          margin-bottom: 0.4rem;
          line-height: 2rem;
        }

        span {
          font-size: 1.4rem;
          color: $grey8;
          line-height: 2rem;

          p {
            display: inline;
          }
        }
      }
    }
  }
}

.label-enhanced-availability {
  color: $grey8;
  font-size: 1.4rem;
  line-height: 2rem;
}

.label-low-availability {
  color: $dark-yellow;
}

.pdp-product-labels,
.pdp-product-promo-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
  top: unset;
}

.pdp-product-promo-message {
  color: $blue_promo;
  font-size: 1.4rem;
  line-height: 2rem;
}

.pdp-product-label {
  display: inline-block;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6;

  font-weight: bold;
  border-radius: 0.5rem;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &-promo {
    color: $blue_promo;
    margin: auto;
    font-size: 1.4rem;
    line-height: 1rem;
    font-weight: 500;

    >* {
      color: $blue_promo;
      margin: auto;
      font-size: 1.4rem;
      line-height: 1rem;
      font-weight: 500;
    }

    &[data-promo-type='employee'],
    &[data-promo-type='promo-dipendenti'] {
      color: $green;
      background: unset;
    }
  }
}

.prices {
  width: 100%;
  display: flex;
  margin-bottom: 3.2rem;
  flex-wrap: wrap;
  gap: 1.6rem;
  align-items: center;

  span {
    line-height: 1.6rem;
    font-size: 2rem;
    color: $blue;
  }
}

[data-isspecialprice='true'] {
  .price {
    del {
      display: none;
    }

    span.value {
      position: relative;
      color: $blue;
      line-height: 2rem;
      background: $special_yellow;
    }
  }
}