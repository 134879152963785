// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

body {
  &#search-show {
    .selector-size-expanded {
      .label-soldout-product {
        &--dash {
          display: none;
        }

        &--similarLink {
          display: none;
        }
      }
    }
  }
}

.swatch-wrapper,
.sticky-tray {
  .swatch-color-container {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 0.1rem solid $grey6;
    justify-content: center;

    &.selected {
      border: 0.3rem solid $white;
      transform: translateZ(0);
      box-shadow: 0 0 0 0.1rem $blue;
    }
  }
}

.selector-size-expanded {
  position: relative;
  color: $black;
  background-color: $white;

  .recommended {
    flex: 1;
    color: $black_secondary;
    margin-left: ($space-unit * 4);

    span:only-of-type {
      display: none;
    }
  }

  .selector{
    &__label{
      margin-right: ($space-unit * 4);

      &-text {
        @extend %primary-xs-u;
      }
  
      &-value {
        &--color{
          margin-left: ($space-unit * 4);
          @extend %primary-xs;          
        }

      }
    }

    &__menu{
      display: flex;
      align-items: center;
      
      &-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 0.4rem;
        border: 0.1rem solid $grey3;
        cursor: pointer;
        transition: all 0.4s ease; 

        &[aria-expanded="true"] {
          border-color: $blue;
          max-height: auto;
          .selector__menu{
            &__label{
              &-wrapper{
                &::after {
                  transform: rotatez(180deg);
                  margin: 0;
                  transition: all 0.4s ease;                  
                }
              }
            }

            &__list{
              max-height: 25.5rem;
            }
          }
        }

        &[aria-expanded="false"] {
          
          .selector__menu {
            &__label {
              &-wrapper {
                border-bottom: 0;

                &::after {
                  margin: 0;
                  transform: rotatez(0);
                  transition: all 0.4s ease;
                }
              }              
            }

            &__list{
              max-height: 0;
              overflow: hidden;
            }
          }
        }
        
        .selector__menu__list{
          height: auto;
          overflow: auto;
          flex-wrap: nowrap;
          transition: all 0.4s ease;
        }
      }

      &__label{
        color: $grey7;
  
        &--selected{
          color: $blue;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 1.2rem;
        }

        &-wrapper{
          width: 100%;
          display: flex;
          justify-content: space-between;
          line-height: 2.2rem;
          padding: 1.2rem 1.6rem;
          border-bottom: 0.1rem solid $grey3;
        }

        &-container{
          width: 100%;
        }
      }

      &__list{
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        bottom: initial;
        min-width: 100%;
        grid-template-columns: repeat($selector-size-inpage-cols-desktop, 1fr);
        column-gap: 0.4rem;
        flex-direction: column;
        
        &::-webkit-scrollbar {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 1rem;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $grey6;
          border-radius: 2rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $grey8;
        }

        &__element{

          border-bottom: 0.1rem solid $grey3;
  
          &:is([disabled]) span {
            opacity: 0.3;
          }
  
          &:hover {
            background-color: $grey1;
            border-radius: 0.4rem;
          }

          &[disabled] {
            cursor: default;
          }
        }
      }


      .label {
        @extend .visually-hidden;
      }
  
      &__item {
        transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
        display: flex;
        justify-content: space-between;
        gap: 1.2rem;
        align-items: center;
        padding: 1.6rem 1.6rem;
        width: 100%;
  
        &.notavailable {
          color: $black_inactive;
        }
  
        &.is-disabled+.selector__menu__item__label {
          color: $black_inactive;
        }
  
        &.notavailable+.selector__menu__item__label {
          color: $black_inactive;
        }
  
        &:not(.is-disabled, .notavailable)+.selector__menu__item__label {
          color: $black;
        }
  
        &__label {
          @extend %primary-s-u;
          position: relative;
          display: inline-block;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $black;
          white-space: nowrap;
  
          &+span {
            text-align: right;
          }
  
          &::after {
            content: '';
            height: 0.1rem;
            bottom: 0;
            background-color: $grey_line;
            width: 100%;
            min-width: 1.6rem;
            @include center('horizontal');
            @include animate(0.3);
            transform: translateX(-50%) scaleX(0);
          }
        }
  
        &.is-disabled {
          color: $black_inactive;
          position: relative;
  
          .selector__menu__item__label {
            color: $grey6;
          }
        }
  
        &.selected {
          background-color: $grey1;
          border-radius: 0.4rem;
        }
  
        @include touchtarget-area();
  
        &:focus {
          outline: none;
  
          @include touchtarget-focus();
        }
      }
    }
  }

  .label{
    &-enhanced-availability {
      color: $grey8;
      font-size: 1.4rem;
      line-height: 2rem;

      [data-tooltip] {
        &::before {
          bottom: unset;
          top: unset;
          position: absolute;
          left: unset;
          right: unset;
          transform: translate(calc(-100% - 0.5rem), -1.5rem);
          text-align: center;
          max-width: 25rem;
        }

        &::after {
          border-top-color: transparent;
          border-left-color: $blue;
          left: -0.6rem;
          top: 0.3rem;
          transform: translate(0, 0);
        }
      }
    }

    &-low-availability {
      color: $dark-yellow;
    }
      
    &-not-personalizable {
      color: $red;
    }

    &-soldout-product {

      &--soldout{
        color: $red;
        display: flex;
        line-height: 2rem;      
      }


      &--dash {
        color: black;
        padding: 0 0.8rem;
      }

      &--similarLink {
        border-bottom: 0.1rem solid $blue;
        padding: 0;

        &::before {
          content: none;
        }
      }
    }
  }

  .errormessage {
    color: $status_error;
    margin-right: ($space-unit * 2);
    @include text-shorten;
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;
    background-color: $black;

    .recommended {
      color: $white_secondary;
    }

    .icon--link .icon--right {
      color: $white;

      &::after {
        filter: invert(1);
      }
    }

    .selector__menu__item {
      color: $white;

      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }

      &.is-disabled {
        color: $white;

        &::after {
          background-color: $white;
        }
      }

      &.selected {
        .selector__menu__item__label {
          position: relative;

          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  //INTILE VARIANT
  &.intile {
    .selector__menu {
      ul {
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
  }
}


.sizevariation-modal .modal__body .selector-size-expanded .selector__menu .size-selector-label-wrapper[aria-expanded=false] ul {
  overflow: auto;
}