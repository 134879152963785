// COUNTRY SELECTOR USED IN: size guide

.selector-country {
  position: relative;
  color: $black;
  display: inline-flex;
  @include animate(0.5, height);
  height: ($space-unit * 10);
  padding-right: 1rem;

  .selector {
    &__button {
      display: flex;
      align-items: center;
      height: 100%;
      @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

      & > span {
        @extend %primary-xxs-u;
      }

      &[aria-expanded='true'] {
        opacity: 0;
        visibility: hidden;
        + .selector__menu {
          opacity: 1;
          visibility: visible;
        }
      }

      &::after {
        content: '';
        right: -#{$space-unit};
        @include fixedSprite('chevron-right');
        @include center('vertical');
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: ($space-unit * 3) 0;
      opacity: 0;
      visibility: hidden;
      @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

      .label {
        @extend .visually-hidden;
      }

      &__list {
        @include reset-list;
        display: flex;
      }

      &__item {
        padding: 0 1.6rem 0 0;
        color: $black_secondary;

        &:hover {
          // ONE-23809
          text-decoration: underline;
        }

        & > span {
          @extend %primary-xxs-u;
        }

        &[aria-pressed='true'] {
          color: $black;
        }
      }
    }
  }
}
