.product-gallery {
  width: 50%;

  &-with-modal {
    .product-container:first-child {
      padding-top: 0;
    }

    .product-image {
      margin-top: 0.8rem;
    }
  }
}

.product-images {
  img {
    width: 100%;
  }
}

.zoom-image-wrapper {
  width: 100%;
  height: auto;
  margin-bottom: 0.4rem;
}

#pdp-gallery-modal .swiper-wrapper {
  flex-direction: column;
}