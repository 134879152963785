.selector-size-expanded {
  .recommended {
    margin-left: 0;
  }
  
  .selector__menu__item{
    padding: 1.5rem 1.2rem;
  }
}

.quickview-content .selector-size-expanded .selector__menu .size-selector-label-wrapper{
  .selector__menu{
    &-wrapper{
      &[aria-expanded=false]{
        .selector__menu__list {
          max-height: calc(70vh - 16.1rem);
          overflow-y: scroll;
        }
      }
    }

    &__list__element{
      max-width: 92vw;
    }
  }
}
