body {
  &#product-show {
    footer {
      margin-bottom: 7.4rem;
    }

    #component-product-gallery-with-modal-app {
      min-height: calc(100vw * 0.907 * 4 / 3);
    }

    .product-container {
      .find-in-store-cta-wrapper {
        .find-in-store-btn {
          border-radius: 0.4rem;
          justify-content: center;
          border: 0.1rem solid $grey3;
          padding: 1.2rem;

          span {
            font-size: 1.4rem;
            line-height: 1.4rem;
          }

          &.store-selected {
            border-radius: 0;
            justify-content: unset;
            border: 0.1rem solid $grey8;
            border-width: 0 0 0.1rem 0;
            padding: 0;
          }


          span {
            border: 0;
            color: $blue;
          }
        }

        .selected-store-detail-pdp {
          .selected-store-detail-pdp-wrapper {
            .pdp-store-selected-grid {
              max-width: 18rem;
            }
          }
        }
      }

      .tag-image {
        padding: 0.5rem 1.2rem;
        height: 2.2rem;
    
        &-wrapper {
          z-index: 3;
          bottom: 1.5rem;
          left: 1.6rem;
          gap: 0.5rem;
        }
    
        &--personalization {
          padding: 0.6rem 0.5rem;
          width: 2.2rem;
          .tag-image__label {
            display: none;
          }
        }
    
        &--double {
          order: -1;
          .tag-image {
            &__label {
              display: none;
            }
          }
        }
      }
    }

    .product-sidebar {

      #variation-attrs {
        .button-wrapper-section {
          .wrapper-selector {
            &.for-size-selection {
              &::after {
                content: unset;
              }
            }

            &.for-color-selection {
              padding: 1rem 0.5rem 1rem 1rem;
              justify-content: space-between;
            }
          }
        }
      }

      .find-in-store-cta-wrapper {

        &.no-padding {
          padding: 0;
        }

        .find-in-store-btn {
          color: $grey8;
          font-size: 1.4rem;
          border-radius: 0.4rem;
          border: 0.1rem solid $grey3;
          padding: 1.6rem;
          width: 100%;
          height: 4rem;

          &::before {
            content: unset;
          }
        }
      }
    }
  }
}

.product-container {
  padding-top: 0;
  min-height: calc(100vw * 0.907 * 4 / 3);

  .product-col {
    min-height: calc(100vw * 0.907 * 4 / 3);
  }

  &.product-wrapper {
    >* {
      padding-top: 0;
    }
  }

  .shipping-date-preview {
    font-size: 1.3rem;
    margin-top: 0;

    .js-modal-info-shipping {
      display: inline;
      color: $blue;
      font-size: 1.3rem;
      cursor: pointer;
    }
  }
}

.button-wrapper-section {
  .swatch-color-container {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 0.1rem solid #8D959C;
  }
}

.product-sidebar {
  width: 100%;

  #variation-attrs {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 3;
    background: $white;
    padding: 1.6rem;
    border-top: 0.1rem solid $grey3;
    min-height: 7.3rem;

    .product-selection.container {
      .color-size-label {
        display: none;
      }
    }

    .button-wrapper-section {
      display: flex;
      justify-content: center;
      gap: 0.8rem;

      .wrapper-selector {
        padding: 1rem 2rem;

        .label-color-value {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 8.8rem;
        }
      }
    }
  }

  .find-in-store-cta-wrapper {

    &.no-padding {
      padding: 0;
    }

    button {
      &::after {
        content: unset;
      }
    }

    .find-in-store-btn-container {
      button {
        border-bottom: 0.1rem solid $grey8;
      }
    }

    .find-in-store-btn {
      color: $grey8;
      font-size: 1.4rem;
      border-radius: 0.4rem;
      border: 0.1rem solid $grey3;
      padding: 1.6rem;
      width: 100%;

      &::before {
        content: unset;
      }
    }
  }
}

.product-container.product-detail.product-wrapper {
  margin: 0;
  padding-top: 0;
  top: 0;
  z-index: 50;

  .product-detail-container {
    width: 100%;
    padding-top: 2.4rem;
  }

  .price-detail-container {
    padding-top: 0;
    width: 100%;

    .price {
      margin: 0;
      min-height: 2rem;
    }
  }
}

.container.d-lg-none.wrapperPdpImages {
  padding: 0;
  margin-bottom: 0;

  .swiper-wrapper {
    background: $white;
    height: unset !important;

    .swiper-slide {
      height: 100%;

      &:only-child {
        width: 100% !important;
      }
    }
  }
}

.product-gallery-with-modal .product-container {
  margin: 0;
  background: $white;
  z-index: 2;
}

.product-detail__name--wrapper .icon--wishlist {
  height: 0;
}

.selector-color-expanded .selector__label {
  padding: 0;
}

.pdp-product-label {
  &-promo {
    margin-left: 0;

    &[data-promo-type='employee'],
    &[data-promo-type='promo-dipendenti'] {
      padding: 0;
    }
  }
}

.product-detail .prices {
  margin-bottom: 0;
}

.info-shipping-modal {
  &.tingle-modal-box {
    width: 100%;
    max-width: 40rem;
  }
}