#pdp-gallery-modal {
  .modal-close {
    display: block;
    background-color: unset;
    position: absolute;

    &::after {
      width: 0;
      height: 0;
      padding: 0;
      position: absolute;
      top: 0;
      margin: 0;
    }
  }

  .swiper-wrapper {
    flex-direction: column;
  }
}

.zoom-image-wrapper {
  min-height: 100%;
  object-fit: cover;

  &:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0;
  }
}