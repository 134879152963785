.product-wall {
  &__title {
    font-size: 3.2rem;
    line-height: 4rem;

    &-wrapper {
      text-align: center;
      padding-bottom: 3.2rem;
    }
  }

  &__wrapper {
    padding: 5.6rem 2.4rem;
    background-color: $gray1;
  }

  &__product {
    grid-column: span 2;
    .product-tile {
      &__card {
        overflow: visible;
        .tile-body {
          margin-bottom: 0;

        }
      }
    }

    &:nth-of-type(6n) {
      .tooltip-container {
        transform: translateX(-94%);
        &::after {
          left: 94%;
        }
      }  
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(12,1fr);
      gap: 2.4rem 1.2rem;
      grid-template-rows: auto;
      width: 100%;
      max-width: 100vw;
    }
  }
}