.product-carousel__carousel {
  &-wrapper {
    &--center {
      justify-content: center;
      align-items: center;
    }
  }
}

.swiperLoading {
  &__container {
    &--suggested {
      height: calc((100vw*4.0917/13) + 5.6rem);
    }      
  }
}