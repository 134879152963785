.modal.sizeguide-pdp-modal {
  max-width: min(65%, 92.4rem);
  max-height: min(80%, 65rem);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 0.8rem;

  &.ReactModal__Content {
    transform: translate(0, 10%);

    &--after-open {
      transform: translate(0);
    }

    &--before-close {
      transform: translate(0, 10%);
    }
  }

  .modal__header,
  .modal__close {
    padding: 2.2rem 2.4rem;
  }

  .wrapper-size-guide-app {
    padding: 2.4rem;
    max-width: 100rem;
    margin: auto;

    #thirdLevelCategories.disabled {
      opacity: 0.4;
    }

    table,
    th,
    td {
      border: 0.1rem solid $gray3;
      border-collapse: collapse;
    }

    table {
      width: 100%;
      margin: 3.2rem 0;

      thead {
        tr {
          border-radius: 0.4rem 0.4rem 0 0;

          th {
            width: 1%;
            background: $blue;
            color: $white;
            padding: 1.4rem;
            vertical-align: middle;
          }
        }
      }

      tbody {
        tr {
          border-radius: 0.4rem 0.4rem 0 0;

          td {
            background: $white;
            color: $blue;
            padding: 1.4rem;
            text-align: center;
          }
        }
      }
    }

    .text-baby {
      .text-baby-description {
        padding-left: 1.6rem;
      }

      .text-wrapper {
        .round-letter {
          background: $gray3;
          border-radius: 50%;
          width: 2rem;
          min-width: 2rem;
          font-size: 1rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;
        }

        p {
          padding-bottom: 1.6rem;
          border-bottom: 0.1rem solid $gray3;
        }
      }
    }

    .wrapper-international-sizes {
      .accordion__header {
        padding: 1.4rem 1.2rem;
      }

      .accordion__panel {
        padding: 0;

        table {
          margin: 0;
        }
      }
    }
  }
}