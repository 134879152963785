// COLOR SELECTOR USED IN: PLP Tile

.selector-color {
  position: relative;
  width: 100%;
  @include border('top');
  color: $black;
  height: $selector-color-inpage-height-desktop;
  overflow: hidden;
  @include animate(0.5, height);

  .selector{
    &__button{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

      &-text {
        @extend %primary-xs-u;
      }

      &[aria-expanded='true'] {
        opacity: 0;
        visibility: hidden;

        +.selector__menu {
          opacity: 1;
          visibility: visible;
        }
      }

      &:disabled {
        opacity: 1;
        color: inherit;
        cursor: inherit;

        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: -#{$space-unit};
        top: 50%;
        transform: translateY(-50%);
        @include fixedSprite('chevron-right');
      }

      .errormessage {
        display: block;
        text-transform: none;
        position: absolute;
        right: 2rem;
        color: $status_error;
      }

      &__label {
        margin-left: ($space-unit * 4);
        text-transform: capitalize;
        @include text-shorten;
      }

      .swatch {
        margin-left: ($space-unit * 2);
        margin-right: ($space-unit * 4);
        flex-shrink: 0;
      }

      &__info {
        margin-left: ($space-unit * 2);
        opacity: 0.5;
        text-transform: initial;
      }      
    }

    &__menu {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: ($space-unit * 3) 0;
      opacity: 0;
      visibility: hidden;
      @include animate(0.5, 'opacity'); // alert: do not change, it may affect focus management

      &__item {
        display: flex;
        align-items: center;

        &__label {
          @extend %primary-xs;
        }

        .swatch {
          @include touchtarget-area();
        }

        &.is-disabled {
          color: $black_secondary;

          .swatch::after {
            content: '';
            position: absolute;
            width: 3.2rem;
            height: 0.1rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            background-color: $black;
          }
        }

        &:focus {
          outline: none;

          .swatch {
            @include touchtarget-focus();
          }
        }

        &.selected {
          .swatch {
            outline: 0.1rem solid $black_inactive;
            outline-offset: 0.3rem;
          }
        }
      }
    }
  }

  &.bottom-divider {
    @include border('bottom');
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    background-color: $black;

    .selector{
      &__button {
        color: $white;

        &::after {
          filter: invert(1);
        }

        [data-whatintent='keyboard'] & {
          &:focus {
            outline: none;

            &::after {
              outline: $outline-width solid $outline-color;
            }
          }
        }
      }

      &__menu {
        background-color: $black;

        &__item {
          color: $white;

          &.is-disabled {
            color: $white_secondary;

            .swatch::after {
              background-color: $white;
            }
          }

          &.selected {
            .swatch {
              outline-color: $white_inactive;
            }
          }

          &:focus {
            .swatch {
              @include touchtarget-focus($outline-color-inverted);
            }
          }
        }
      }
    }
  }

  //INTILE VARIANT

  &.intile {
    height: $selector-size-intile-height;
  }
}

