.listing-container {
  padding-top: 5rem;

  .page-title {
    margin-bottom: 0;
  }

  .wrapper-page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .plp-top {
    .swiper-container-horizontal {
      overflow: visible;
    }

    .wrapper-subcontainer {
      max-width: 80.9rem;
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .subcategories-container {
        &--low {
          justify-content: flex-end;
          transform: translateX(1.2rem);
          .swiper-wrapper {
            width: fit-content;
          }
        }
        &.swiper-container{
          width: 72.7rem;   
          
          .swiper-wrapper {
            max-width: 72.7rem;
          }
        }

      }
    }

    .subcategories-container:not(.swiper-container) {
      .item-wrapper {
        flex: 0 0 auto;
        height: auto;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: row;
        cursor: auto;
        overflow-x: unset;
        overflow-y: unset;
        gap: 1rem 0;
      }
    }
  }
}


.plp-top-container-space-extra {
  margin-top: 4rem;
}

.plp-top-container {
  margin-bottom: 4rem;
  min-height: 5.7rem;

  .wrapper-page-title {
    display: flex;
  }

  .plp-top-search-field {
    &::before {
      content: '';
      @include fixedSprite('search-big');
      position: absolute;
      bottom: 0.8rem;
    }

    input {
      background: transparent;
      border: 0;
      border-bottom: 0.1rem solid $gray6;
      padding: 0 0 0.8rem 5.2rem;
      width: 48rem;
      font-size: 4rem;
      line-height: 4.8rem;
    }

    .icon--close {
      display: none;
    }
  }
}

.listing-container {
  &.is-shelf {
    padding-top: $header-height-desktop + 10rem;
  }

  .product-list {
    .product-tile {
      flex-basis: 25%;
    }
  }
}

.plppromo {
  picture {
    height: calc(100% - 8.8rem);
  }

  img {
    position: absolute;
    height: auto;
    min-height: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}