.product-carousel__carousel-container,
.product-trending__carousel-container,
.complete-your-look-carousel,
.recommendations-carousel,
.infinite-carousel {
  .swiper{
    &-wrapper{
      img:not(:first-of-type) {
        display: none;
      }
    }

    &-button-container {
      display: none;
    }
  }

  .tile-body .row {
    align-items: flex-start;
  }
}

.swiperLoading {
  &__container {
    height: calc((100vw* 1.0129) + 5.6rem);
    &--suggested {
      height: calc((100vw* 1.28765) + 5.6rem);
    }
  }      
}

.product-carousel{
  &__carousel-container {

    .recommendations-carousel {
      .product-carousel__swiper-container {
        padding: 0 1.6rem;   
      }
      .product-carousel__title {
        font-size: 2.4rem;
        line-height: 3.2rem;
        
        &-wrapper {
          &.product-carousel-wrapper {
            .swiper-button-container {
              display: none;
            }
          }          
        }

      }
    }

    .product-tile__link h2 {
      -webkit-line-clamp: 1;
      white-space: nowrap;
    }

    .plp-mobile-color-size-selection {
      margin-top: 1.2rem;

      .button-wrapper-section {
        width: 100%;
      }
    }

    .cart:last-of-type {
      margin-bottom: 0;
    }

    &.cart {
      padding-top: 3.2rem;
    }
    
  }

  &__text {
    text-align: left;
    margin-top: $space-unit * 8;
    margin-bottom: $space-unit * 6;
  }
}

body{
  &#product-show {
    &:has(#related-product-pdp-carousel) {
      #product-details-wear-it-with {
        margin-bottom: 0;
      }
    }
  }
}

#product-details-wear-it-with {
  padding: 0;
  margin: 6.4rem 0;
  background-color: transparent;

  .complete-your-look-title {
    padding-left: 2.4rem;
  }

  .shop-the-look-btn {
    display: none;
  }
}

.swiper{
  &-recommendations-button-prev,
  &-recommendations-button-next,
  &-button-container {
    display: none;
  }
}


.complete-your-look-container .complete-your-look-carousel {
  grid-template-columns: repeat(2, 1fr);
}