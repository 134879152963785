.grid-container {
  &.grid-4 {
    .product-tile {
      .plp-mobile-color-size-selection {
        display: none;
      }
    }
  }
}
.plp-top-container {
  .wrapper-subcontainer {
    .subcategories-container.swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .btn-subcategory,
          &.btn-subcategory {
            padding: 0;
            height: 23rem;
            width: 23rem;
            max-width: 23rem;
            margin-right: 0;
          }
        }
      }    
    }
  }
}