.product-selection {
  padding-left: $space-unit*0.5;

  &__actions-shipping {
    .button {
      height: auto;
    }
  }

  &__modelinfo {
    position: absolute;
    bottom: $space-unit * 8;
    margin-bottom: 0;
  }
}
