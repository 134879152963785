.swiper-wrapper-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: all;
}

.infinite-carousel .swiper-container-free-mode>.swiper-wrapper {
  transition-timing-function: linear;
  margin: 0 auto;
}

.swiper-pagination--bars {
  position: static;
  display: flex;
  justify-content: center;
  margin: ($space-unit * 4) 0 $space-unit;

  .swiper-pagination-bullet {
    height: 0.2rem;
    width: 6.6rem;
    border-radius: 0;
    background: $grey_line;
    opacity: 1;
    transition: all 0.4s;
    cursor: pointer;
    margin: 0 0.2rem;
    position: relative;
    @include animate(0.3);

    [data-whatintent='keyboard'] & {
      &:focus {
        outline: none;

        &::after {
          content: '';
          position: absolute;
          top: -0.3rem;
          bottom: -0.3rem;
          right: -0.3rem;
          left: -0.3rem;
          border: $outline-width solid $outline-color;
          outline: $outline-width solid $outline-color-inverted;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -0.5rem;
      bottom: -0.5rem;
      right: 0;
      z-index: 2;
    }

    &.swiper-pagination-bullet-active {
      background: $black;
    }
  }
}

.swiper-pagination {
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6rem;
  width: 100%;
  margin: 0;
  padding-bottom: 1rem;
}

.swiper-pagination-bullet {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border: 0.1rem solid $grey7;
  background-color: $grey7;
  transition: background-color ease-out 0.4s;
  margin: 0.4rem;

  &.swiper-pagination-bullet-active {
    background-color: $red;
  }

  &:not(:last-of-type) {
    margin-right: 0.4rem;
  }
}

.swiper {
  opacity: 0;
  transition: opacity 0.3s ease-out 0.4s;

  &-container-initialized {
    opacity: 1;
  }
}

.recommendations-carousel .swiper-wrapper,
.recommendations-carousel .swiper-container {
  z-index: unset;
  transition-property: transform;
  &--loading {
    position: absolute;
    opacity: 0;
  }
}

.product-trending__carousel-container {
  overflow: hidden;

  .swiper-container:not(.product-image) {
    margin-top: 8rem;
  }
}
