.product-selection {
  &__heading {
    padding-top: ($space-unit * 4);

    // padding-bottom: $space-unit;
    &.container {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
      @include z;
    }

    &--placeholder {
      width: 100%;
      display: block;
      position: relative;
    }
  }

  &__ctas {
    position: sticky;
    z-index: 3;
    bottom: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    background-color: $white;
    display: none;

    button {
      height: ($space-unit * 12);

      span {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }

  &__actions-shipping {
    &.on-pdp {
      @include border('top');
      padding-top: 2.4rem;
    }
  }
}
