.breadcrumb {
  display: flex;
  flex-grow: 1;
  padding-left: 0;
  flex-wrap: wrap;
  padding-top: 0;

  .plp-title {
    flex-grow: 1;
  }

  ol {
    @include reset-list;
  }

  li {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }

    &:not(:last-child) {

      &.text-white {
        &::after {
          background-color: $white;
          opacity: 1;
        }
      }

      &::after {
        content: '';
        height: 1.2rem;
        width: 0.1rem;
        background-color: $blue;
        opacity: 0.2;
        margin: 0 ($space-unit * 2);
      }
    }
  }

  &__item {
    @extend %primary-s-u;
    text-decoration: none;
    display: inline-block;
  }
}