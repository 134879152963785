.tooltip {
  position: relative;
  
  &:hover{
    .tooltip-container{
      opacity: 1;
      visibility: visible;
    }
  }

  &-container {
    max-width: min(40vw, 24rem);
    width: max-content;
    background-color: $white;
    color: $black;
    border-radius: 0.6rem;
    padding: 1.6rem;
    position: absolute;
    z-index: 5;
    bottom: calc(100% + 1.5rem);
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 1rem 2rem 0 #0000001A;
    transition: all 0.3s ease-out 0.1s;
    line-height: 1.8rem;
    text-align: left;

    &:after{
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 1.6rem);
      z-index: 1000;
      pointer-events: none;
      user-select: none;
      background: transparent;
      border: 0.8rem solid transparent;
      border-top-color: $white;
    }

    &:hover{
      opacity: 1;
      visibility: visible;
    }
  }
}