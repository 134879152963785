.quickview-selectsize.modal.sizevariation-modal {
  transform: translate(0);
  height: 100%;
  max-height: 100%;
  width: 60%;
  min-width: auto;
  left: unset;
  right: 0;
  top: 0;
  padding: 0;

  .modal__body {
    width: 100%;
    margin-left: auto;
    max-height: unset;
    display: flex;

    .productDetails {
      &__img {
        &-wrapper {
          width: 50%;
          margin-left: 0;
        }

        &-container {
          margin: 0;
        }
      }

      &__body {
        &-wrapper {
          height: 100vh;
          position: sticky;
          top: 0;
          width: 50%;
          border-left: 0.1rem solid $grey_disabled;
        }

        &__details {
          position: relative;
          max-height: 100vh;
          padding: 4rem 3rem;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          .sizevariation-product {
            &__content {
              margin-left: 0;
            }

            &__info {

              &--name {
                font-size: 2rem;
                line-height: 2.8rem;
                width: calc(100% - 2rem);
              }

              &__promoPrice {
                gap: 0.8rem 1.2rem;
                margin-bottom: 1.2rem;
              }

              &--prices {
                &-item {
                  gap: 1.2rem
                }
              }

              &-wrapper {
                position: relative;
                display: flex;
              }
            }
          }

          .product-selection {
            margin-top: 2rem;

            &.container {
              position: unset;
              padding: 0;
              height: auto;

              .selector-color-expanded {
                .selector__menu {
                  margin-bottom: 4.8rem;
                }
              }

              .size-container-section {
                .counter {
                  &__output {
                    padding: 0;
                    width: 2rem;
                  }
                }
              }

              .product-selection__wrapper {
                .product-selection__size {
                  display: none;
                }

                .size-container-section {
                  padding-top: 0;
                }
              }

              .btn-add-to-wishlist-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.info-modal {
  &.modal-message {
    padding: 3.2rem;
    width: auto;
    min-width: 70%;
    height: auto;
    max-height: 90vh;
    max-width: 90vw;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .modal__header {
      padding: 0 0 1.6rem;
      margin-bottom: 1.6rem;

      h2 {
        padding: 0;
      }
    }
  }
}
