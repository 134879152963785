.sizevariation-modal {
  &.whishlist-selectsize {
    transform: translate(0);
    height: 100%;
    max-height: 100%;
    width: 41.4rem;
    min-width: auto;
    left: unset;
    right: 0;
    top: 0;
    border-left: 0.1rem solid $grey6;
    padding: 0;

    .modal__header {
      display: none;
    }

    .product-selection__wrapper {
      .selector-size-expanded {
        .selector__menu {
          padding: 2.4rem 0 0;
        }
      }
    }

    .modal__close {
      padding: 2.8rem 2.4rem;
    }
  
    .selector__menu {
      padding: 0;
    }
  
    .modal__body {
      padding: 0 2.4rem 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .product-selection__ctas {
        bottom: 0;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 2.4rem 2.4rem;
        border-top: 0.1rem solid $grey3;

        .btn-add-to-wishlist-wrapper {
          width: 100%;
        }
      }
    }
  
    .button.button--primary {
      width: 100%;
    }
  
    .dialog__title {
      padding: 2rem 2.4rem;
    }
  }

  #dialog1_label {
    span {
      color: $gray7;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &.modal {
    padding: 2.4rem 1.6rem;
  }

  .modal__close {
    padding: 0;
    top: 2.4rem;
    right: 3rem;
  }

  .modal__header,
  .modal-header {
    padding-top: 0;
    font-size: 1.6rem;
    line-height: 1.25;

  }

  .product-selection__wrapper {
    .selector-size-expanded {
      border-top: none;
    }
  }

  .sizevariation-product {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    overflow: hidden;
    padding-bottom: $space-unit * 4;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }

    .product-image {
      height: auto;
      flex-basis: 9rem;
    }

    >.row {
      flex-grow: 1;
    }

    &__divider {
      @include border('top');
      height: $space-line;
      margin-top: $space-unit * 4;
      margin-bottom: $space-unit * 4;
    }

    &__content {
      position: relative;
      margin-left: $space-unit * 4;
      display: flex;
      flex: 1;
      flex-direction: column;

      //justify-content: space-between;
      >.bag-product__preorderinfo {
        display: none;
      }
    }

    &__out-of-stock {
      position: absolute;
      color: $status_error;
      margin-top: 1rem;
    }

    &__pricename {
      margin-bottom: 1.6rem;
      line-height: 1.8rem;

      h4 {
        margin-bottom: 0.4rem;
      }
    }

    &__name-price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: -0.15rem;

      &__name {
        @extend %primary-s;
        @include del-link;
      }

      &__message {
        @extend %primary-xs;
        color: $black_secondary;
        margin-top: $space-unit * 2;
        margin-bottom: -($space-unit * 4);
        text-align: right;
      }
    }

    &__details {
      flex-grow: 1;
      color: $grey8;
      //margin-top: $space-unit * 5;
      //margin-bottom: $space-unit * 5;

      p {
        @extend %primary-xs;
      }

      div:not(:last-child) {
        margin-bottom: $space-unit * 2;
      }
    }
  }
}

#account-wishlist{
  .modal {
    &.sizevariation-modal {
      &.quickview-selectsize {
        position: fixed;
        right: 0;
        left: unset;
        transform: unset;
        top: 0;
        height: 100%;
        width: 58.5651vw;
        padding: 0;

        .modal__body {
          display: flex;
          max-height: 100%;

          .productDetails{
            &__img{
              &-wrapper{
                width: 50%;
                height: 100vh;
                overflow: auto;

                &::-webkit-scrollbar {
                  display: none;
                }                  
              }
            }

            &__body{
              &-wrapper{
                width: 50%;
                position: relative;                  
              }

              &__details{
                position: unset;
                height: calc(100vh - 8.8rem);
                &__promo{
                  display: none;
                }

                .product-selection{
                  margin-top: 1.6rem;

                  &__ctas{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    padding: 2.4rem;
                  }

                  .size-container-section {
                    padding-top: 0.6rem;
                  }

                }

                .sizevariation-product{
                  &__info{
                    &-wrapper{
                      margin-bottom: 1.6rem;
                    }

                    &--prices{
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
}

