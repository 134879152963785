#pdp-gallery-modal {

  .modal{
    &-container{
      height: 100%;
    }

    &-content{
      height: 100%;
    }

    &-close{
      width: 4rem;
      height: 4rem;
      top: 2.4rem;
      right: 2.4rem;
      z-index: 2;
      display: none;
      @include rounded-box(5rem);
  
      &::after {
        @include sprite('close');
        width: 10rem;
        height: 10rem;
        padding: 0.8rem;
        background-origin: content-box;
      }
    }
  }
  
  .icon--close {
    background: none;
  }

  .product-zoom-modal {
    padding: 0;
  }

  .swiper-wrapper {
    overflow: auto;
    cursor: url(../images/close12.png), auto;
  }
}

.product-gallery {
  width: 100%;
}

.zoom-image-wrapper {
  width: 100%;
  height: auto;
}

.product-images .pdp-section {
  img {
    width: 100%;
    height: calc((100vw/2)*(4/3));
  }
}