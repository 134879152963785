.quickview-selectsize.modal.sizevariation-modal {
  width: 100%;
  height: auto;
  top: unset;

  .modal__body {
    .productDetails{
      &__img{
        &-wrapper{
          width: 0;        
        }
      }

      &__body{
        &-wrapper{
          width: 100%;
          height: auto;          
        }

        &__header{
          &-wrapper{
            padding: 2.2rem 1.6rem;
            border-bottom: 0.1rem solid $grey3;
            position: relative;            
          }

          &-title{
            font-size: 1.6rem;
            line-height: 2rem;            
          }

          &-btn {
            top: 50%;
            right: 1.6rem;
            transform: translate(0, -50%);
          }          
        }

        &__details{
          padding: 1.6rem;

          &__header{
            &-img{
              height: auto;
              min-width: 12rem 
            }

            .sizevariation-product{
              &__info{
                &-wrapper{
                  margin-bottom: 0.2rem;
                }

                &--name{
                  line-height: 1.6rem;
                  font-size: 1.3rem;                  
                }

                &--prices{
                  &-item{
                    font-size: 1.3rem;                    
                  }                 
                }
              }
            }          
          }

          &__promo{
            display: none;
          }

          .product-selection {
            .button-wrapper-section {
              display: flex;
              flex-direction: column;
              gap: 0.8rem;

              .wrapper-selector {
                justify-content: space-between;
                padding: 1.2rem 2.4rem;

                .selector__label {
                  .swatch {
                    border-radius: 50%;
                    height: 1.4rem;
                    width: 1.4rem;

                  }
                }
              }
            }

            &.container{
              margin-top: 2.4rem;

              .color-size-label {
                color: $grey7;
              }

              .button-wrapper-section {
                .button {
                  padding: 1.6rem;

                  .size-selector-wishlist {
                    width: 100%;
                    justify-content: space-between;
                  }
                }
              }

              .btn-add-to-wishlist-wrapper {
                margin-top: 4.6rem;
              }              
            }
          }         
        }
      }
    }
  }

  &.modal-wishlist-plp {
    .modal__body {
      .productDetails{
        &__body{
          &__details{
            .product-selection{
              &.container{
                .button-wrapper-section {
                  .button {
                    color: $blue;
                    background-color: $white;
                    border: 0.1rem solid $grey4;
    
                    &__label {
                      color: $blue;
                    }
                  }
                }                
              }
            }
          }
        }
      }
    }
  }
}

.info-modal {
  &.modal-message {
    height: fit-content;
    margin-top: auto;
  }
}
